import React from "react";

// styles-import
import "./OnSuccessResponseIconView.scss";

const OnSuccessResponseIconView = () => {
  return (
    <div className="ui-success__wrapper">
      <img
        src="/images/onsuccess-tick.svg"
        className={`success-status-icon`}
        alt="success status icon"
      />
    </div>
  );
};

export default OnSuccessResponseIconView;
