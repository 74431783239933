import React from "react";

// styles import
import "./TabHeading.scss";

const TabHeading = ({ headingText = "Tab Title Here" }) => {
  return (
    <>
      <h2 className="ui-tab-heading">{headingText}</h2>
    </>
  );
};

export default TabHeading;
