function convertKeysToCamelCase(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item));
  }

  return Object.keys(obj).reduce((camelCaseObj, key) => {
    const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
      letter.toUpperCase()
    );
    camelCaseObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
    return camelCaseObj;
  }, {});
}

export function snakeToCamelCase(array) {
  return array.map((item) => convertKeysToCamelCase(item));
}
