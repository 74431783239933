import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
// Axios
import axios from "axios";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { APIConfig } from "../../../services/apiConfiguration";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// utils
import { randomUUID } from "../../../services/randomUUID";
// ui component
import FormHeading from "../../../UI/FormHeading/FormHeading";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import DMOResponseDetails from "./VPAResponseDetails copy/DMOResponseDetails";
// context
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const DMO = ({ setEnableDMONextToSummaryBtn }) => {
  // ^ context
  const { consumerURN } = useContext(OnBoardCompanyPAContext);

  // & state
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  // & constant
  const dmoReferenceID = randomUUID();

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const initialValues = {
    reference_id: dmoReferenceID,
    provider_code: "yesb",
    consumer_urn: consumerURN,
  };

  const validationSchema = Yup.object({
    reference_id: Yup.string().required("Reference ID is required"),
    provider_code: Yup.string().required("Provider code ID is required"),
    consumer_urn: Yup.string().required("Consumer URN is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      //! reset previous values
      handleClick();

      dmoMerchantOnBoardingAPI(values);
    },
  });

  //* DMO Merchant Onboarding API
  const dmoMerchantOnBoardingAPI = (values) => {
    APIConfig.API_Client.post(
      paAPIEndpoints.MERCHANT_ONBOARDING_DMO.baseUrl +
        paAPIEndpoints.MERCHANT_ONBOARDING_DMO.endpoint,
      {
        reference_id: dmoReferenceID,
        provider_code: "yesb",
        consumer_urn: values.consumer_urn,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails(response.data);
        setShowResponseDetails(true);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response?.data?.message} />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        // #
        setResponseDetails(error?.response?.data);
        setShowResponseDetails(true);
        // #
        setEnableDMONextToSummaryBtn(true);
        // * snackbar for error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        // #
        setEnableDMONextToSummaryBtn(true);
        formik.setSubmitting(false);
      });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormHeading headingText="Digital Merchant Onboarding" />
        <div className="ui-form-details">
          <div className="ui-form-content">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="consumer_urn"
                name="consumer_urn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.consumer_urn}
                touched={formik.touched.consumer_urn}
                error={formik.errors.consumer_urn}
                placeholder="Enter consumer urn"
                label="Consumer URN"
                required={true}
                disabled={true}
              />
              <TextFieldInput
                id="provider_code"
                name="provider_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.provider_code}
                touched={formik.touched.provider_code}
                error={formik.errors.provider_code}
                placeholder="Select provider"
                label="Provider"
                required={true}
                disabled={true}
              />
            </div>
            {/* 2 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${
                  formik.isValid && !formik.isSubmitting ? "active" : "disabled"
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>

      {showResponseDetails && (
        <DMOResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};
export default DMO;
