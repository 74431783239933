import React from "react";
import ProductionTabs from "../ProductionTabs/ProductionTabs";
import "./ProductionTabPanel.scss";

const ProductionTabPanel = () => {
  return (
    <div className="menu-container-dev">
      <ProductionTabs />
    </div>
  );
};

export default ProductionTabPanel;
