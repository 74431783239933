import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";
// google auth imports
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
// api config imports
import { setAuthTokens } from "axios-jwt";
import axios from "axios";
import {
  APIConfig,
  AuthResponseToAuthTokens,
} from "../../services/apiConfiguration.js";
import apiEndpointList from "../../config/modules/customer_management/endpoint.js";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint.js";
// route imports
import { useHistory } from "react-router-dom";
// shared component imports
import SnackbarMessage from "../../components/SnackbarMessage/SnackbarMessage.js";
// styles imports
import "./GoogleLoginPage.scss";

const GoogleLoginPage = () => {
  let history = useHistory();
  const [errorMessage, setErrorMessage] = useState(false);

  /**
   * success call on account selection
   * We're creating a payload object that contains the user's email, a blank password, and the user's
   * google token.
   * @param googleUser - The user object returned by Google.
   */
  const onSuccess = (googleUser) => {
    let requestPayload = {
      email: googleUser.profileObj.email,
      password: "",
      google_user_token: googleUser.getAuthResponse().id_token,
    };

    // For using in TOTP generation
    localStorage.setItem("google_user_token", requestPayload.google_user_token);

    // * on success api call
    axios
      .post(
        // apiEndpointList.GOOGLE_LOGIN.baseUrl +
        //   apiEndpointList.GOOGLE_LOGIN.endpoint,
        paAPIEndpoints.GOOGLE_LOGIN.baseUrl +
          paAPIEndpoints.GOOGLE_LOGIN.endpoint,
        requestPayload
        // APIConfig.BASE_URL + "/frontend/user/internal/auth", requestPayload
      )
      .then((response) => {
        setErrorMessage("");
        setAuthTokens(AuthResponseToAuthTokens(response.data));
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: response.data.name,
            email: response.data.email,
            client_id: response.data.client_id,
          })
        );
        history.push("/dashboard");
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
        // if (error?.response?.status === 404) {
        // * on failure register api
        // initiateRegister(googleUser);
        // } else {
        //   ReactDOM.render(
        //     <SnackbarMessage
        //       msgtype="Error"
        //       msg={error?.response?.data?.message || "Something went wrong !"}
        //     />,
        //     document.getElementById("snackbar")
        //   );
        // }
      });
  };

  //^ api call on user is not registered before .
  // const initiateRegister = (googleUser) => {
  //   let requestPayload = {
  //     company_id: process.env.REACT_APP_COMPANY_ID,
  //     set_active: true,
  //     name: googleUser.profileObj.name,
  //     user_type: 3,
  //     email: googleUser.profileObj.email,
  //     google_user_token: googleUser.getAuthResponse().id_token,
  //   };

  //   let headers = {
  //     client_id: process.env.REACT_APP_REGISTER_CLIENT_ID,
  //     client_secret: process.env.REACT_APP_REGISTER_CLIENT_SECRET,
  //     "Content-Type": "application/json",
  //   };

  //   axios
  //     .post(
  //       apiEndpointList.USER_REGISTER.baseUrl +
  //         apiEndpointList.USER_REGISTER.endpoint,
  //       requestPayload,
  //       // APIConfig.BASE_URL + apiEndpointList.USER_REGISTER, requestPayload,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       setErrorMessage("");
  //       onSuccess(googleUser);
  //     })
  //     .catch((e) => {
  //       console.log("Error signup", e);
  //     });
  // };

  /**
   * If the user closes the popup window, set the error message to the response
   * @param response - The response object returned by Auth0.
   */
  const onFailure = (response) => {
    if (response.error === "popup_closed_by_user") {
      setErrorMessage(response);
    }
  };

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_API_GOOGLE_LOGIN_CLIENT_ID,
        scope: "openid profile email",
        plugin_name: "Decentro DB",
      });
    });
  }, []);

  return (
    <div className="google-login">
      <div className="google-form-section">
        <div className="google-input-section">
          <img
            className="decentro-logo"
            src="./images/logo.svg"
            alt="decentro logo"
          />
          <h1>Welcome back! Please login to your account.</h1>

          <GoogleLogin
            clientId={process.env.REACT_APP_API_GOOGLE_LOGIN_CLIENT_ID}
            render={(renderProps) => (
              <div>
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="google-login-button"
                  type="submit"
                >
                  <img
                    className="google-login-icon"
                    src="/images/google-icon.png"
                    alt="google"
                  />{" "}
                  Sign in with Google
                </button>

                {!!errorMessage && (
                  <div
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="alert"
                  >
                    The window was closed before completing Google
                    Authentication.
                  </div>
                )}
              </div>
            )}
            buttonText="Login"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
          ></GoogleLogin>
        </div>
      </div>
    </div>
  );
};

export default GoogleLoginPage;
