import React, { useState } from "react";
// component imports
import OnBoardingSummary from "../SortTableSummary/OnBoardingSummary";
import TabHeading from "../../UI/TabHeading/TabHeading";
import { VaDetailModal } from "./VaDetailModal/VaDetailModal";
// styles imports
import "./AllConsumers.scss";

const AllConsumers = () => {
  const [showVaDetailModal, setShowVaDetailModal] = useState(false);
  const openFetchVaModal = () => {
    setShowVaDetailModal(true);
  };

  return (
    <React.Fragment>
      <div className="customer-configuration-page">
        <div className="customer-configuration-content">
          <div className="customer-configuration">
            <TabHeading headingText="All Consumers" />
          </div>
          <div className="customer-configuration-card">
            {showVaDetailModal ? (
              <VaDetailModal setShowModal={setShowVaDetailModal} />
            ) : null}
            <OnBoardingSummary />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllConsumers;
