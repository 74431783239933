import React, { createContext, useState } from "react";
import { APIConfig } from "../services/apiConfiguration";
import axios from "axios";
import ReactDOM from "react-dom";
import UserManagementSuccess from "../components/UserManagement/UserManagementSuccess/UserManagementSuccess";
import Loader from "../components/Loader/Loader";
import SnackbarMessage from "../components/SnackbarMessage/SnackbarMessage";
import apiEndpointList from "../config/modules/customer_management/endpoint";

const UserActionContext = createContext();
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const UserActionProvider = ({ children }) => {
  // type of card state
  const [cardType, setCardType] = useState("userSummary");
  // edit user state
  const [tableEditRow, setTableEditRow] = useState({ item: "", edit: false });
  //manage modal state
  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    setOpenModal(true)
  }

  //to call the edit user page
  const editUser = () => {
    setCardType("editUser");
  };
  //To call the Summary table
  const userSummary = () => {
    setCardType("userSummary");
    setTableEditRow({ item: "", edit: false });
  };

  const createUserApi = (payload, company, user, resetInputs) => {
    ReactDOM.render(<Loader />, document.getElementById("user-config-loader"));
    APIConfig.API_Client.post(
      apiEndpointList.USER_REGISTER.baseUrl + apiEndpointList.USER_REGISTER.endpoint,
      // APIConfig.BASE_URL + apiEndpointList.USER_REGISTER
      payload, {
      cancelToken: source.token,
    })
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );
        ReactDOM.render(
          <UserManagementSuccess
            company={company}
            user={user}
            data={payload}
          />,
          document.getElementById("add-user-components")
        );
        resetInputs();
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };
  const editUserApi = (payload, company, user) => {
    ReactDOM.render(<Loader />, document.getElementById("user-config-loader"));
    APIConfig.API_Client.put(
      apiEndpointList.USER_UPDATE_REGISTER.baseUrl + apiEndpointList.USER_UPDATE_REGISTER.endpoint,
      // APIConfig.BASE_URL + apiEndpointList.USER_UPDATE_REGISTER, 
      payload, {
      cancelToken: source.token,
    })
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );
        ReactDOM.render(
          <UserManagementSuccess
            company={company}
            user={user}
            data={payload}
          />,
          document.getElementById("add-user-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };
  return (
    <UserActionContext.Provider
      value={{
        cardType,
        tableEditRow,
        setTableEditRow,
        userSummary,
        editUser,
        createUserApi,
        editUserApi,
        setOpenModal,
        showModal,
        openModal
      }}
    >
      {children}
    </UserActionContext.Provider>
  );
};

export default UserActionContext;
