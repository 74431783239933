import React from "react";

//styles-import
import "./ResponseDetails.scss";

//components-import
import CopyChip from "../CopyChip/CopyChip";
// utils-import
import getSelectedProperties from "../../utilities/getSelectedProperties";

const ResponseDetails = ({ data, onClick }) => {

  const status =
    data.responseData.message === "User updated" || "User registered"
      ? "success"
      : "failure";
  const imgSrc =
    status === "failure"
      ? "/images/onfailure-error.svg"
      : "/images/onsuccess-tick.svg";

  const desiredProperties = ['company_id', 'name', 'email', 'isExisting', 'mobile', 'set_active'];

  const selected = getSelectedProperties(data?.renderData, desiredProperties) || "-"
  const copyableData = {
    user_type: data.renderData.user_type_name || "-",
    ...selected,
  }

  return (
    <>
      <div className="response-details-container">
        <h4 className="response-details-heading">
          <img
            src={imgSrc}
            className={`${status}-status-icon`}
            alt="status-icon"
          />
          {status === "failure" ? "Failure" : "Success"} Response Details
          <div className="right-icons-container">
            <CopyChip
              label="Copy All"
              color="primary"
              variant="outlined"
              textToCopy={copyableData !== null ? copyableData : "-"}
              iconSrc="/images/copy-icon.svg"
            />
            <img
              src="/images/close.svg"
              className="close-response-details"
              alt="close"
              onClick={onClick}
            />
          </div>
        </h4>
        <div className="response-details-divider"></div>
        <div className="response-details">
          {status === "success" ? (
            <ul className="response-list">
              <li className="response-list-item">
                <span className="field">Company ID: </span>
                <span className="value">
                  {data.renderData.company_id || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">User Name: </span>
                <span className="value">{data.renderData.name || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">User Type: </span>
                <span className="value">
                  {data.renderData.user_type_name || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Email: </span>
                <span className="value">{data.renderData.email || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">Mobile: </span>
                <span className="value">{data.renderData.mobile || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">Status: </span>
                <div
                  className={`value ${data.renderData.set_active == true
                    ? "status-active"
                    : "status-inactive"
                    }`}
                >
                  {data.renderData.set_active == true
                    ? "Active"
                    : "Inactive" || "-"}
                </div>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ResponseDetails;
