import React from "react";
import ReactDOM from "react-dom";
import SnackbarMessage from "../components/SnackbarMessage/SnackbarMessage";

function copyToClipboardTable(classValue) {
    const elTable = document.querySelector(classValue);

    let range, sel;

    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
          document.getElementById("snackbar")
        );
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();
  }

export default copyToClipboardTable;
