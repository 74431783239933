import React, { useContext } from "react";

// component imports
import ManageIpsForm from "./ManageIpsForm/ManageIpsForm";
import IPManagementDataTable from "../IPManagementDataTable/IPManagementDataTable";
import ComponentWrapper from "../../UI/ComponentWrapper/ComponentWrapper";
import TabHeading from "../../UI/TabHeading/TabHeading";
import FeatureUnavailable from "../../UI/FeatureUnavailable/FeatureUnavailable";

// context imports
import IPManagementActionContext from "../../contexts/IPManagementActionContext";

const IPManagement = () => {
  const { cardType, allCompanies, tableEditData } = useContext(
    IPManagementActionContext
  );

  const IP_MANAGEMENT_CARD = {
    editIps: (
      <ComponentWrapper
        heading={`Edit IPs`}
        headingHighlight={tableEditData.companyName}
        showBackBtn={true}
        backBtnCallback={allCompanies}
        component={<ManageIpsForm />}
      />
    ),
    allCompanies: <IpManagementSummaryCard />,
  };

  if (process.env.REACT_APP_ENV_NAME === "staging") {
    return (
      <React.Fragment>
        <ComponentWrapper
          heading="IP Management"
          component={<FeatureUnavailable />}
        />
      </React.Fragment>
    );
  }

  return (
    <div>
      <div>{IP_MANAGEMENT_CARD[cardType]}</div>
    </div>
  );
};

const IpManagementSummaryCard = () => {
  return (
    <div className="user-management-page">
      <div className="user-management-content">
        <div className="user-management">
          <TabHeading headingText="Manage IPs" />
        </div>
        <div className="user-management-card">
          <IPManagementDataTable />
        </div>
      </div>
    </div>
  );
};

export default IPManagement;
