import React from "react";

//styles-import
import "./ResponseDetails.scss";
// components-import
import CopyChip from "../../../UI/CopyChip/CopyChip";
// utils-import
import getSelectedProperties from "../../../utilities/getSelectedProperties";


const ResponseDetails = ({ data, onClick }) => {
  const status = data.responseData.status === "Success" ? "success" : "failure";
  const imgSrc =
    status === "failure"
      ? "/images/onfailure-error.svg"
      : "/images/onsuccess-tick.svg";

  const desiredProperties = ['company_id', 'execution_id', 'message'];

  const selected = getSelectedProperties(data, desiredProperties) || "-"
  const copyableData = selected

  return (
    <>
      <div className="response-details-container">
        <h4 className="response-details-heading">
          <img
            src={imgSrc}
            className={`${status}-status-icon`}
            alt="status-icon"
          />
          IPs updated successfully!
          <div className="right-icons-container">
            <CopyChip
              label="Copy All"
              color="primary"
              variant="outlined"
              textToCopy={copyableData !== null ? copyableData : "-"}
              iconSrc="/images/copy-icon.svg"
            />
            <img
              src="/images/close.svg"
              className="close-response-details"
              alt="close"
              onClick={onClick}
            />
          </div>
        </h4>
        <div className="response-details-divider"></div>
        <div className="response-details">
          {status === "success" ? (
            <ul className="response-list">
              <li className="response-list-item">
                <span className="field">Company ID: </span>
                <span className="value">
                  {data.renderData.company_id || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Execution ID: </span>
                <span className="value">
                  {data.responseData.execution_id || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Message: </span>
                <span className="value">
                  {data.responseData.message || "-"}
                </span>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ResponseDetails;
