export default function getSelectedProperties(obj, propertiesToKeep) {
    function findProperty(obj, prop) {
        if (obj.hasOwnProperty(prop)) {
            return obj[prop];
        }
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                let result = findProperty(obj[key], prop);
                if (result !== undefined) {
                    return result;
                }
            }
        }
        return undefined;
    }

    return propertiesToKeep.reduce((result, prop) => {
        let value = findProperty(obj, prop);
        if (value !== undefined) {
            result[prop] = value;
        }
        return result;
    }, {});
}