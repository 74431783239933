import React, { useContext } from "react";
// api config imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
// component imports
import DataTable from "./DataTable";
import TableLoader from "../Shared/TableLoader/TableLoader";
import Error from "../Shared/Error/Error";
// context imports
import CallbackActionContext from "../../contexts/CallbackActionContext";
// utilities imports
import capitalizeFirstLetter from "../../utilities/capitalizeLetter";
import { snakeToCamelCase } from "../../utilities/snakeToCamelCase";
import { wrapText } from "../../utilities/tableTextWrap";
// style imports
import "./CallbackConfigurationSummary.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CallbackConfigurationSummary = () => {
  const { setTableEditData, configureNewCallback } = useContext(
    CallbackActionContext
  );

  function handleEdit(row) {
    setTableEditData({ item: row, edit: true });
    configureNewCallback();
  }

  // const wrapText = (text, maxLength) => {
  //   const regex = new RegExp(`.{1,${maxLength}}`, "g");
  //   return text.match(regex).join("\n");
  // };

  // table header
  const callbackHeader = React.useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        columns: [
          // {
          //   Header: "Company Id",
          //   accessor: "companyId",
          //   className: "header-small",
          //   sort: false,
          // },
          {
            Header: "Company Name",
            accessor: "companyName",
            className: "header-medium",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.companyName
                  ? capitalizeFirstLetter(row.row.original.companyName)
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Method Type",
            accessor: "httpMethod",
            className: "header-small",
            sort: false,
          },
          {
            Header: "Url",
            accessor: "subscriberUrl",
            className: "header-large",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center", whiteSpace: "pre-line" }}>
                {row?.row?.original?.subscriberUrl
                  ? wrapText(row?.row?.original?.subscriberUrl, 25)
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Subscription Protocol",
            accessor: "subscriptionProtocol",
            className: "header-small",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.subscriptionProtocol
                  ? capitalizeFirstLetter(row.row.original.subscriptionProtocol)
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Callback Type",
            accessor: "callbackType",
            className: "header-medium",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.callbackType
                  ? row.row.original.callbackType.replace(/_/g, " ")
                  : "-"}
              </div>
            ),
          },
          // {
          //   Header: "Action",
          //   accessor: "action",
          //   className: "header-small last-child-header",
          //   sort: false,
          //   Cell: (row) => (
          //     <div>
          //       <button
          //         onClick={(e) => {
          //           handleEdit(row.row.original);
          //         }}
          //         className="callback-table-edit-btn"
          //       >
          //         Edit
          //         <img src="/images/back.svg" alt="right-arrow" />
          //       </button>
          //     </div>
          //   ),
          // },
        ],
      },
    ],
    []
  );

  // states
  const [callBackValue, setCallBackValue] = React.useState("");

  // Error Handling states
  const [isError, setIsError] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState({
    status: "",
    message: "",
  });

  // * callback company data
  const callbackApi = () => {
    APIConfig.API_Client.get(
      apiEndpointList.GET_CALLBACK_DETAILS.baseUrl +
        apiEndpointList.GET_CALLBACK_DETAILS.endpoint,
      { cancelToken: source.token }
    )
      .then((response) => {
        // * Response casing change functionality
        const responseToCamelCase = snakeToCamelCase(response.data.data);

        setCallBackValue(responseToCamelCase);
      })
      .catch((error) => {
        setIsError(true);
        setErrorResponse({
          status: error.response.status,
          message: error.message,
        });
      });
  };

  React.useEffect(() => {
    callbackApi();
  }, []);

  return (
    <div className="all-callback-table-container">
      {!isError ? (
        callBackValue ? (
          <DataTable
            columns={callbackHeader}
            data={callBackValue}
            csvName="CallbackConfigurationSummaryDetails"
          />
        ) : (
          <TableLoader />
        )
      ) : (
        <Error type={errorResponse} />
      )}
    </div>
  );
};

export default CallbackConfigurationSummary;
