import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Axios
import axios from "axios";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Components
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import { TotpModal } from "../TotpModal/TotpModal";
// Utils
import { APIConfig } from "../../../services/apiConfiguration";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
import { REGEXP } from "../../../utilities/validators/inputValidators";
import { randomUUID } from "../../../services/randomUUID";

const InternalFundsTransfer = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [transferTypes, setTransferTypes] = useState([]);
  const [transferTypesLoading, setTransferTypesLoading] = useState(false);
  const [responseData, setResponseData] = useState();
  const [showResponse, setShowResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    isError: false,
  });

  // TOTP Specific
  const [isLoading, setIsLoading] = useState(true);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const initialValues = {
    transfer_type: "",
    amount: "",
    from_account_number: "",
    to_account_number: "",
    reference_id: "",
    purpose_message: "",
  };

  const { laAccountRegex, referenceIdRegex, amountRegex } = REGEXP;

  const validationSchema = Yup.object({
    transfer_type: Yup.object().shape({
      value: Yup.string().required("Please select an option"),
      label: Yup.string().required("Please select an option"),
    }),
    amount: Yup.string()
      .required("Amount is required")
      .matches(amountRegex, "Invalid Amount"),
    from_account_number: Yup.string()
      .required("Account Number is required")
      .matches(laAccountRegex, "Invalid Account Number"),
    to_account_number: Yup.string()
      .required("Account Number is required")
      .matches(laAccountRegex, "Invalid Account Number"),
    reference_id: Yup.string()
      .required("Reference ID is required")
      .matches(referenceIdRegex, "Invalid Reference ID"),
    purpose_message: Yup.string().required("Purpose Message is required"),
  });

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
      paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const handleSubmitFundTransferRequest = (formik) => {
    const { values, action } = formik;
    const payload = {
      ...values,
      amount: +values?.amount,
      transfer_type: values?.transfer_type?.value,
    };
    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      apiEndpointList.INTERNAL_FUNDS_TRANSFER.baseUrl +
      apiEndpointList.INTERNAL_FUNDS_TRANSFER.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setErrorResponse({
          isError: false,
        });
        setResponseData(response.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Success"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponse(true);
        formik.resetForm();
      })
      .catch((error) => {
        setErrorResponse((prev) => ({
          ...prev,
          isError: true,
          ...error.response.data,
        }));
        setResponseData("");
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponse(true);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  const getTransferTypes = () => {
    try {
      setTransferTypesLoading(true);
      APIConfig.API_Client.post(
        apiEndpointList.GET_TRANSFER_TYPES.baseUrl +
        apiEndpointList.GET_TRANSFER_TYPES.endpoint,
        { cancelToken: source.token }
      ).then((response) => {
        const formattedResponse = response.data.map(({ name }) => ({
          value: name,
          label: name,
        }));
        setTransferTypes(formattedResponse);
      });
    } catch (error) {
      console.error("error from getTransferTypes", error);
    } finally {
      setTransferTypesLoading(false);
    }
  };

  useEffect(() => getTransferTypes(), []);

  const closeResponse = () => {
    setShowResponse(false);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="transfer_type"
                name="transfer_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("transfer_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("transfer_type", true)}
                value={formik.values.transfer_type}
                options={transferTypes}
                noOptionsMessage={() => "No such Transfer Type exists"}
                label="Transfer Type"
                required={true}
                placeholder="Select the transfer type"
                isformatOptionLabel={false}
                isClearable={false}
                isLoading={transferTypesLoading}
              />
              <TextFieldInput
                id="amount"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                touched={formik.touched.amount}
                error={formik.errors.amount}
                placeholder="Enter amount"
                label="Amount"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="from_account_number"
                name="from_account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.from_account_number}
                touched={formik.touched.from_account_number}
                error={formik?.errors?.account_number}
                placeholder="Enter from account"
                label="From Account"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="to_account_number"
                name="to_account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.to_account_number}
                touched={formik.touched.to_account_number}
                error={formik.errors.to_account_number}
                placeholder="Enter to account"
                label="To Account"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="purpose_message"
                name="purpose_message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose_message}
                touched={formik.touched.purpose_message}
                error={formik.errors.purpose_message}
                placeholder="Enter the purpose message"
                label="Purpose Message"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : "disabled"
                  }`}
                type="submit"
                disabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={(formik) => handleSubmitFundTransferRequest(formik)}
          formik={formik}
        />
      ) : null}
      {showResponse && (
        <ResponseDetails
          data={responseData}
          onClick={closeResponse}
          errorResponse={errorResponse}
        />
      )}
    </>
  );
};

export default InternalFundsTransfer;
