import React, { useContext, useState } from "react";
// component imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// context imports
import CallbackContext from "../../../contexts/CallbackContext";
// utilities
import Divider from "../../../UI/Divider/Divider";
import copyToClipboard from "../../../utilities/copyToClipboard";
import { randomUUID } from "../../../services/randomUUID";
import { wrapText } from "../../../utilities/textWrap";
import { snakeToCamelCase } from "../../../utilities/snakeToCamelCase";
// styles imports
import "./ListCallback.scss";
import { TotpModal } from "../NewCallback/TotpModal/TotpModal";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ListCallback = ({ currentModal, setCurrentModal }) => {
  // & All callback state
  const [callBackValue, setCallBackValue] = useState("");

  // ^ context
  const {
    listIds,
    resetCallback,
    callbackEditItem,
    openModal,
    deleteCallbackApi,
    searchCompanyID,
    showTotpModal,
    setShowTotpModal,
    openTotpModal,
  } = useContext(CallbackContext);
  // & Delete callback Key
  const [deleteCallbackKey, setDeleteCallbackKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (deleteKey) => {
    setDeleteCallbackKey(deleteKey);
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
        setCurrentModal("DeleteCallback");
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  // * list callbacks
  const callbackExistCheckApi = () => {
    APIConfig.API_Client.get(
      apiEndpointList.GET_CALLBACK_DETAILS.baseUrl +
        apiEndpointList.GET_CALLBACK_DETAILS.endpoint,
      // + "/" + `${listIds?.companyId}`
      { cancelToken: source.token }
    )
      .then((response) => {
        // * Response casing change functionality
        const responseToCamelCase = snakeToCamelCase(response.data.data);

        setCallBackValue(responseToCamelCase);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    callbackExistCheckApi();
  }, [listIds, resetCallback]);

  let mappedResult;
  if (callBackValue.length > 0) {
    mappedResult = callBackValue

      .map((item) => {
        return {
          ...item,
        };
      })
      .filter((company) => company.companyName === listIds.commonName)
      .reverse();
  }

  if (!callBackValue) {
    return (
      <div className="callback-loader-container">
        <img src="/images/loader.gif" alt="loader" />
      </div>
    );
  }

  // * copy header details
  const copyResponseDetail = (headerData) => {
    copyToClipboard(headerData);
  };

  // * DELETE callback handler
  const handleDeleteCallback = (callbackType) => {
    let payload = {
      reference_id: randomUUID(),
      company_id: searchCompanyID,
      callback_type: callbackType,
    };

    // delete's callback
    deleteCallbackApi(payload);
  };

  return (
    <div className="added-callbacks">
      {/* <h1>List Callbacks</h1> */}
      <h2 className="edit-list-heading">
        {mappedResult.length === 0 ? "No Callbacks Found" : "Added Callbacks"}
      </h2>
      <Divider />
      {mappedResult &&
        mappedResult.map((item) => {
          return (
            <div key={item.subscriberUrl} className="callback-list__container">
              <div className="field-details">
                <div className="list-row__left">
                  <div className="response">
                    <span className="response-label">Callback</span>
                    <span className="response-value">
                      {item?.callbackType?.replaceAll("_", " ") || "NIL"}
                    </span>
                  </div>

                  <div className="response">
                    <span className="response-label">Header</span>
                    <span className="response-value">
                      {item?.subscriptionHeaders
                        ? JSON.stringify(item?.subscriptionHeaders).slice(
                            0,
                            45
                          ) + "..."
                        : "NIL"}

                      {item?.subscriptionHeaders &&
                      Object.keys(item?.subscriptionHeaders)?.length ? (
                        <img
                          className="copy-header-icon"
                          onClick={() =>
                            copyResponseDetail(
                              JSON.stringify(item?.subscriptionHeaders || "-")
                            )
                          }
                          src="/images/copy-icon.svg"
                          alt="copy-icon"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>

                <div className="list-row__right">
                  <div className="response">
                    <span className="response-label">HTTP</span>
                    <span className="response-value">
                      {item.httpMethod || "NIL"}
                    </span>
                  </div>

                  <div className="response url-callback-value">
                    <span className="response-label">URL</span>
                    <span className="response-value url-callback-value">
                      {/* {wrapText(item?.subscriberUrl, 24) || "NIL"} */}
                      {item?.subscriberUrl || "NIL"}

                      {item?.subscriberUrl &&
                      Object.keys(item?.subscriberUrl)?.length ? (
                        <img
                          className="copy-header-icon ml-4 pointer-cursor"
                          onClick={() =>
                            copyResponseDetail(item?.subscriberUrl)
                          }
                          src="/images/copy-icon.svg"
                          alt="copy-icon"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="action-btn-wrapper">
                <div className="edit-btn-wrapper">
                  <button
                    onClick={() => {
                      callbackEditItem(item);
                      openModal();
                    }}
                    className="callback-edit-btn"
                  >
                    Edit
                  </button>
                </div>

                {/* * Commenting for now as currently API is not working from BE.  */}
                <div className="delete-btn-wrapper">
                  <button
                    onClick={() => submitHandler(item.callbackType)}
                    // onClick={() => handleDeleteCallback(item.callbackType)}
                    className="callback-delete-btn"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      {/* TOTP modal */}
      {showTotpModal && currentModal === "DeleteCallback" ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          deleteKey={deleteCallbackKey}
          deleteCallback={handleDeleteCallback}
        />
      ) : null}
    </div>
  );
};

export default ListCallback;
