import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import { REGEXP } from "../../utilities/validators/inputValidators";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

// context-imports
import DashboardUserPAContext from "../../contexts/DashboardUserPAContext/DashboardUsersContext";

// utils-import
import { randomUUID } from "../../services/randomUUID";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import ToggleField from "../../UI/ToggleField/ToggleField";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../UI/TextField/TextField.scss";
import { TotpModal } from "./TotpModal/TotpModal";

// regex-imports
const { editUsernameRegex, phoneNumberRegex, passwordRegex } = REGEXP;

// for conditional rendering. 
const communicationUser = "Communication user"

const validationSchema = Yup.object({
  // company_name: Yup.object().shape({
  //   value: Yup.number().required("Please Select an Option"),
  //   label: Yup.string().required("Please Select an Option"),
  // }),
  user_type: Yup.object().shape({
    value: Yup.string().required("Please Select an Option"),
    label: Yup.string().required("Please Select an Option"),
  }),
  user_name: Yup.string()
    .matches(editUsernameRegex, "Invalid Name format")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(phoneNumberRegex, "Invalid Mobile number")
    .max(10)
    .required("Mobile number is required"),
  password: Yup.string().matches(passwordRegex, "Invalid Password format"),
  // .required("Password is required"),
  is_active: Yup.boolean().when('user_type', {
    is: (user_type) => user_type && user_type.label !== "Communication user",
    then: () => Yup.boolean().required("Boolean Status required"),
    otherwise: () => Yup.boolean().notRequired(),
  }),
});

let userTypeDropDownValues = [
  {
    value: "3",
    label: "ADMIN",
  },
  {
    value: "4",
    label: "USER",
  },
];

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const EditNewUser = () => {
  // ^ context
  const { tableEditRow } = useContext(DashboardUserPAContext);
  const [userTypeOptions, setUserTypeOptions] = useState([""]);
  const [isUserTypesLoading, setIsUserTypesLoading] = useState(true);

  // getting user types
  const getUserType = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GET_USER_TYPES.baseUrl +
      apiEndpointList.GET_USER_TYPES.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.id,
          label: item.display_name,
        }));
        setUserTypeOptions(options);
        setIsUserTypesLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setUserTypeOptions([]);
        setIsUserTypesLoading(false);
      });
  };

  useEffect(() => {
    getUserType()
  }, []);

  function transformInput(input) {
    // Replace underscores with spaces
    let words = input.replace(/_/g, ' ').split(' ');

    // Capitalize only the first word
    words = words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    });

    // Join the words back together
    return words.join(' ');
  }
  //& Formik Initial values
  const initialValues = {
    company_name: tableEditRow?.item?.company_common_name,
    user_type: {
      label: transformInput(tableEditRow?.item?.user_type),
      value: tableEditRow?.item?.user_type_id,
    },
    user_name: tableEditRow?.item?.name,
    email: tableEditRow?.item?.email,
    mobile: tableEditRow?.item?.mobile,
    password: "",
    is_active: !!tableEditRow?.item?.is_active,
  };

  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const openTotpModal = () => {
    setShowTotpModal(true);
  };

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const editNewUser = (formik) => {
    const { values, action } = formik;

    let payload = {
      reference_id: randomUUID(),
      company_id: tableEditRow?.item?.company_id,
      user_type: values?.user_type?.value,
      name: values.user_name,
      email: values.email,
      mobile: values.mobile,
      set_active: true,
      password: values.password || "",
    };

    if (values?.user_type?.name !== communicationUser) {
      payload.password = values.password;
      payload.set_active = values.is_active;
    }

    if (!values.password) {
      delete payload.password;
    }

    formik.setSubmitting(true);
    APIConfig.API_Client.put(
      paAPIEndpoints.USER_UPDATE_REGISTER.baseUrl +
      paAPIEndpoints.USER_UPDATE_REGISTER.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails({
          ...responseDetails,
          responseData: response.data,
          renderData: {
            ...payload,
            user_type_name: values?.user_type?.label
          },
        });

        formik.setValues({
          ...values,
          password: "" // Clear password field
        });

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        setShowResponseDetails(true);
      })
      .catch((error) => {
        // setResponseDetails(error.response);
        // setShowResponseDetails(true);

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");
  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
      paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler(values, action);
      // let payload = {
      //   reference_id: randomUUID(),
      //   company_id: tableEditRow?.item?.company_id,
      //   user_type: values?.user_type?.value,
      //   name: values.user_name,
      //   email: values.email,
      //   mobile: values.mobile,
      //   set_active: values.is_active,
      //   password: values.password || "",
      // };

      // if (!values.password) {
      //   delete payload.password;
      // }

      // action.setSubmitting(true);
      // APIConfig.API_Client.put(
      //   paAPIEndpoints.USER_UPDATE_REGISTER.baseUrl +
      //     paAPIEndpoints.USER_UPDATE_REGISTER.endpoint,
      //   payload,
      //   { cancelToken: source.token }
      // )
      //   .then((response) => {
      //     setResponseDetails({
      //       ...responseDetails,
      //       responseData: response.data,
      //       renderData: payload,
      //     });
      //     setShowResponseDetails(true);

      //     ReactDOM.render(
      //       <SnackbarMessage msgtype="success" msg={response.data.message} />,
      //       document.getElementById("snackbar")
      //     );

      //     // action.resetForm();
      //   })
      //   .catch((error) => {
      //     // setResponseDetails(error.response);
      //     // setShowResponseDetails(true);

      //     ReactDOM.render(
      //       <SnackbarMessage
      //         msgtype="Error"
      //         msg={error?.response?.data?.message || "Something went wrong!"}
      //       />,
      //       document.getElementById("snackbar")
      //     );
      //   })
      //   .finally(() => {
      //     action.setSubmitting(false);
      //   });
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          {/* <div className="ui-divider"></div> */}
          <div className="ui-form-content ui-divider">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="company_name"
                name="company_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_name}
                touched={formik.touched.company_name}
                error={formik.errors.company_name}
                placeholder="Company Name"
                label="Company"
                required={true}
                disabled={true}
              />

              <TextFieldSelect
                id="user_type"
                name="user_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("user_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("user_type", true)}
                value={formik.values.user_type}
                options={userTypeOptions}
                noOptionsMessage={() => "No Such User Type exists"}
                label="User Type"
                required={true}
                isLoading={isUserTypesLoading}
                placeholder="Select User Type"
                isformatOptionLabel={false}
                isClearable={false}
              />
            </div>

            {/* 2 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="user_name"
                name="user_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.user_name}
                touched={formik.touched.user_name}
                error={formik.errors.user_name}
                placeholder="Enter username"
                label="Name"
                required={true}
                disabled={false}
              />

              <TextFieldInput
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                touched={formik.touched.email}
                error={formik.errors.email}
                placeholder="Enter desired email"
                label="Email"
                required={true}
                disabled={true}
              />
            </div>

            {/* 3 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="mobile"
                name="mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                touched={formik.touched.mobile}
                error={formik.errors.mobile}
                placeholder="Enter mobile number"
                label="Mobile Number"
                maxLength={10}
                required={true}
                disabled={false}
              />

              {formik.values?.user_type?.label !== communicationUser && (
                <TextFieldInput
                  id="password"
                  name="password"
                  inputType="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  touched={formik.touched.password}
                  error={formik.errors.password}
                  placeholder="Enter password"
                  label="Password"
                  required={false}
                  disabled={false}
                />
              )}
            </div>

            <div className="ui-form-inputs-section">
              {formik.values?.user_type?.label !== communicationUser && (
                <ToggleField
                  id="is_active"
                  isToolTip={false}
                  value={formik.values.is_active}
                  checked={formik.values.is_active}
                  onChange={formik.handleChange}
                  label="Status"
                  required={true}
                  disabled={false}
                />
              )}
            </div>

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : "disabled"
                  }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* TOTP modal */}
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          // submitCallback={editNewUser}
          submitCallback={(formik) => editNewUser(formik)}
          formik={formik}
        />
      ) : null}
      {showResponseDetails && (
        <ResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};

export default EditNewUser;
