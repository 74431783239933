import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

// formik-imports
import { useFormik } from "formik";
import * as Yup from "yup";

// ui-components
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import OnSuccessResponseIconView from "../../../../UI/OnSuccessResponseIconView/OnSuccessResponseIconView";

// api-config-imports
import axios from "axios";
import { APIConfig } from "../../../../services/apiConfiguration";

// utils-imports
import { randomUUID } from "../../../../services/randomUUID";
import paAPIEndpoints from "../../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// context-imports
import OnBoardCompanyPAContext from "../../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";

// styles-import
import "../CheckBoxFormStyles.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ProviderSelectionForm = ({ setEnableProviderBtn }) => {
  // ^ context
  const { companyID, masterAccountSetupAPI } = useContext(
    OnBoardCompanyPAContext
  );

  // ^ button state on success
  const [onSuccess, setOnSuccess] = useState(false);

  // const [providerIds, setProviderIds] = useState({
  // yes_bank: 1,
  // dbs_bank: 3, // Commented out for future use
  // Add more fields and their corresponding provider IDs if needed
  // });

  const validationSchema = Yup.object().shape({
    yes_bank: Yup.boolean(),
    // dbs_bank: Yup.boolean(), // Commented out for future use
    // Add more fields if needed
  });

  const formik = useFormik({
    initialValues: {
      yes_bank: true,
      // dbs_bank: true, // Commented out for future use
      // Add more fields if needed
    },
    validationSchema,
    onSubmit: (values, action) => {
      let bankCode = {
        yes_bank: 1,
        // dbs_bank: 4,
      };

      // Function to check true values and assign bank codes
      function assignBankCodes(initialValues, bankCode) {
        let result = [];

        // Iterate through the initialValues object
        for (let key in initialValues) {
          if (initialValues[key] === true && bankCode.hasOwnProperty(key)) {
            result.push({
              id: bankCode[key],
              key: randomUUID(),
            });
          }
        }

        return result;
      }

      let assignedProviderCodes = assignBankCodes(values, bankCode);

      action.setSubmitting(true);

      APIConfig.API_Client.post(
        paAPIEndpoints.COMPANY_PROVIDER_SECRET.baseUrl +
          paAPIEndpoints.COMPANY_PROVIDER_SECRET.endpoint,
        { company_id: companyID, provider_details: assignedProviderCodes },
        { cancelToken: source.token }
      )
        .then((response) => {
          ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={response.data.message} />,
            document.getElementById("snackbar")
          );

          setOnSuccess(true);

          // Master account setup // MLA
          masterAccountSetupAPI();

          // To enable next jump to settlement account setup Tab validation state
          setEnableProviderBtn(true);

          // action.resetForm();
        })
        .catch((error) => {
          setOnSuccess(false);

          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error.response.data.message}
            />,
            document.getElementById("snackbar")
          );
        })
        .finally(() => {
          action.setSubmitting(false);
        });
    },
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    formik.setFieldValue(name, checked);
  };

  return (
    <div className="checkbox-form-wrapper">
      <form onSubmit={formik.handleSubmit}>
        <div className="formik-checkbox-field">
          <label className="formik-checkbox-field__label">
            <input
              type="checkbox"
              name="yes_bank"
              checked={formik.values.yes_bank}
              onChange={handleCheckboxChange}
              className="formik-checkbox-field__input"
              disabled
            />
            Yes Bank
          </label>
        </div>
        {/* Commented out the DBS bank checkbox */}
        {/* <div className="formik-checkbox-field">
          <label className="formik-checkbox-field__label">
            <input
              type="checkbox"
              name="dbs_bank"
              checked={formik.values.dbs_bank}
              onChange={handleCheckboxChange}
              className="formik-checkbox-field__input"
            />
            DBS bank
          </label>
        </div> */}
        {/* Add more checkboxes for other fields if needed */}

        {onSuccess ? (
          <OnSuccessResponseIconView />
        ) : (
          <button
            className={`formik-btn-submit ${
              !formik.values.yes_bank ? "disabled" : "" // Disable button if "Yes bank" is unchecked
            } ${formik.isSubmitting ? "disabled" : ""}`}
            type="submit"
            disabled={
              !formik.isValid || formik.isSubmitting || !formik.values.yes_bank
            }
          >
            {formik.isSubmitting ? "Loading..." : "Submit"}
          </button>
        )}
      </form>
    </div>
  );
};

export default ProviderSelectionForm;
