import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./services/privateRoute.js";
import Login from "./pages/Login/Login.js";

import GoogleLoginPage from "./pages/GoogleLoginPage/GoogleLoginPage.js";
import ProductionDashboard from "./pages/ProductionDashboard/ProductionDashboard.js";
import Changelog from "./pages/Changelog/Changelog.js";

// styles import
import "./app.scss";

function App() {
  const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
  const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

  const isDark = window.matchMedia("(prefers-color-scheme: dark)");

  const changeFavicon = () => {
    if (isDark.matches) {
      document.head.append(darkSchemeIcon);
      lightSchemeIcon.remove();
    } else {
      darkSchemeIcon.remove();
      document.head.append(lightSchemeIcon);
    }
  };

  React.useEffect(() => {
    changeFavicon();
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      isDark.addListener(changeFavicon);
    } else {
      isDark.addEventListener("change", changeFavicon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="app-main">
      <div className="app-content">
        <div id="snackbar"></div>
        <div id="failure-popup"></div>
        <div id="add-customer-components"></div>
        <div id="add-user-components"></div>
        <Router>
          <Switch>
            {/* <Route path="/dashboard">
              <Dashboard />
            </Route> */}
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/login">
              {/* <Login /> */}
              <GoogleLoginPage />
            </Route>
            <PrivateRoute path="/dashboard" component={ProductionDashboard} />
            {/* Add a env based check for render the Dashboard component based on env  */}
            {/* {process.env.REACT_A`PP_REGISTER_CLIENT_ID === "decentro_prod" ? (
              <PrivateRoute path="/dashboard" component={ProductionDashboard} />
            ) : (
              <PrivateRoute path="/dashboard" component={Dashboard} />
            )} */}
            <PrivateRoute path="/changelog" component={Changelog} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
