import React, { useContext, useEffect, useState } from "react";
// component imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// context imports
import CallbackContext from "../../../contexts/CallbackContext";
import CallbackActionContext from "../../../contexts/CallbackActionContext";
// styles imports
import "./HttpTypeDropdown.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const HttpTypeDropdown = ({ setHttpId, setHttpTypeName }) => {
  const [selected, setSelected] = useState("POST");
  const [isActive, setIsActive] = useState(false);
  const [httpMethod, setHttpMethod] = useState("");

  // * Context
  const { resetCallback } = useContext(CallbackContext);
  const { tableEditData } = useContext(CallbackActionContext);

  useEffect(() => {
    setSelected("POST");
  }, [resetCallback]);

  useEffect(() => {
    if (tableEditData.edit) {
      setSelected(tableEditData?.item?.httpMethod || "POST");
    }
  }, [tableEditData]);

  const httpMethodList = () => {
    const check = localStorage.getItem("callbackMethodType");

    if (check) {
      setHttpMethod(JSON.parse(check));
    } else {
      APIConfig.API_Client.post(
        apiEndpointList.CALLBACK_HTTP_METHOD_TYPE.baseUrl +
          apiEndpointList.CALLBACK_HTTP_METHOD_TYPE.endpoint,
        // APIConfig.BASE_URL + "/frontend_sa/decentro/read/fetch/http_method_type",
        {},
        { cancelToken: source.token }
      )
        .then((response) => {
          localStorage.setItem(
            "callbackMethodType",
            JSON.stringify(response.data)
          );
          setHttpMethod(response.data);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    httpMethodList();
  }, []);

  if (httpMethod.length === 0) {
    return (
      <div className="httptype-loader-skeleton">
        <span className="skeleton-loader"></span>
      </div>
    );
  }

  return (
    <div className="dropdown-http-type">
      <div className="dropdown-http-label">HTTP Method</div>
      <div
        className={`dropdown-btn ${
          selected === "POST" ? "text-light" : "text-dark"
        } ${isActive && " border-radius__invert"}`}
        onClick={(e) => setIsActive(!isActive)}
      >
        {selected}
        {/* {selected.length !== 1 && (
          <span>
            <DownArrow isActive={isActive} />
          </span>
        )} */}
      </div>
      {isActive && (
        <div className="http-type-content">
          {httpMethod.map((option) => (
            <div key={option.id}>
              {option.name !== selected && (
                <div
                  key={option.id}
                  onClick={(e) => {
                    setSelected(option.name);
                    setIsActive(false);
                    setHttpId(option.id);
                    setHttpTypeName("POST");
                  }}
                  className="dropdown-item"
                >
                  {"POST"}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function DownArrow({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 dropdown-arrow ${
        isActive && "dropdown-arrow__rotate"
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default HttpTypeDropdown;
