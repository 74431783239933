import React from "react";
import PropTypes from "prop-types";

//component-import
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ToolTip from "../ToolTip/ToolTip";

//styles-import
import "../TextField/TextField.scss";

const MultiInputField = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  touched,
  error,
  placeholder,
  label,
  required,
  isToolTip,
  extraHint = {},
}) => {
  const hasError = touched && Boolean(error && error.length > 0);
  return (
    <div className="ui-form-input-section ui-form-content-input">
      <div className="ui-form-elements-wrapper">
        <div className="ui-form-element-control">
          <Autocomplete
            className="autocomplete-multi-input-wrapper"
            id={id}
            name={name}
            multiple
            freeSolo
            options={[]}
            value={value}
            onChange={(event, value) => onChange(name, value)}
            onBlur={() => onBlur(name, true)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                error={touched && Boolean(error && error.length > 0)}
                helperText={
                  hasError && typeof error == "string"
                    ? error
                    : hasError && error.length > 1
                    ? "Invalid IP addresses"
                    : hasError && error.length === 1
                    ? error[0]
                    : null
                }
                InputLabelProps={{
                  shrink: false,
                }}
              />
            )}
          />
          {extraHint && <p className="extra-hint">{extraHint}</p>}
        </div>
      </div>
      <label htmlFor={id} className="custom-ui-label">
        {label}{" "}
        <ToolTip
          text={isToolTip}
          requiredField={required}
          isToolTip={isToolTip}
        >
          {isToolTip && (
            <div className="ui-info-icon">
              <img src="/images/info-icon.svg" alt="" />
            </div>
          )}
        </ToolTip>
      </label>
    </div>
  );
};

MultiInputField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default MultiInputField;
