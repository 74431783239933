import React from "react";
import "./UserManagementDataTable.scss";
import { useContext } from "react";
// component imports
import DataTable from "../SortTableSummary/DataTable";
import Error from "../Shared/Error/Error.js";
// api config imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
//ui element
import TableLoader from "../Shared/TableLoader/TableLoader";
//context imports
import DashboardUserPAContext from "../../contexts/DashboardUserPAContext/DashboardUsersContext";
import UserActionContext from "../../contexts/UserActionContext";
//utilities imports
import capitalizeFirstLetter from "../../utilities/capitalizeLetter";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
function UserManagementDataTable() {
  // ^ context
  // let { setTableEditRow, editUser } = useContext(UserActionContext);
  let { setTableEditRow, editUser } = useContext(DashboardUserPAContext);

  //handling edit button click
  function handleEdit(row) {
    setTableEditRow({
      item: row,
      edit: true,
    });
    editUser();
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // Dynamic useTable headers for data-table
  const onBoardedCompanyTableHeaders = React.useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        showColumnFilter: true,
        columns: [
          {
            Header: "Company ID",
            accessor: "company_id",
            className: "header-small",
            sort: false,
            // disable the filter for particular column
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.company_id
                  ? row.row.original.company_id
                  : "-"}
              </div>
            ),
          },
          // {
          //   Header: "User ID",
          //   accessor: "user_id",
          //   className: "header-small",
          //   sort: true,
          //   // disable the filter for particular column
          //   Cell: (row) => (
          //     <div style={{ textAlign: "center" }}>
          //       {row.row.original.user_id ? row.row.original.user_id : "-"}
          //     </div>
          //   ),
          // },
          {
            Header: "Email",
            accessor: "email",
            className: "header-large",
            sort: false,
            // disable the filter for particular column
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.email ? row.row.original.email : "-"}
              </div>
            ),
          },
          {
            Header: "User Name",
            accessor: "name",
            className: "header-large",
            sort: false,
            // disable the filter for particular column
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.name
                  ? capitalizeFirstLetter(row.row.original.name)
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Contact",
            accessor: "mobile",
            className: "header-medium",
            sort: false,
            // disable the filter for particular column
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.mobile ? row.row.original.mobile : "-"}
              </div>
            ),
          },
          {
            Header: "User Type",
            accessor: "user_type",
            className: "header-medium",
            sort: false,
            Filter: SelectColumnFilter, //adding a column filter
            filter: "equals",
            // disable the filter for particular column
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.user_type.length > 0
                  ? row.row.original.user_type
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Status",
            accessor: "is_active",
            className: "header-small",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.is_active > 0 ? "Active" : "Inactive"}
              </div>
            ),
          },
          {
            Header: "Action",
            accessor: "action",
            className: "header-small",
            sort: false,
            Cell: (row) => (
              <div className="edit-btn">
                <button
                  onClick={(e) => {
                    handleEdit(row.row.original);
                  }}
                  className="user-table-edit-btn"
                >
                  Edit
                  <img src="/images/back.svg" alt="right-arrow" />
                </button>
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  let clientData = [];

  const [mappedValue, setMappedValue] = React.useState("");
  // Error Handling states
  const [isError, setIsError] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState({
    status: "",
    message: "",
  });

  const getAPISummaryData = () => {
    APIConfig.API_Client.post(
      apiEndpointList.GET_DASHBOARD_USERS.baseUrl +
        apiEndpointList.GET_DASHBOARD_USERS.endpoint,
      // APIConfig.BASE_URL + apiEndpointList.GET_DASHBOARD_USERS,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        let row = [];
        clientData = [];
        for (let i = 0; i < response.data.length; i++) {
          row = response.data[i];
          clientData.push(row);
        }
        tableDataSummary(clientData);
      })
      .catch((error) => {
        setIsError(true);
        setErrorResponse({
          status: error.response.status,
          message: error.message,
        });
      });
  };
  const tableDataSummary = (data) => {
    setMappedValue(data);
  };
  React.useEffect(() => {
    getAPISummaryData();
  }, []);

  return (
    <div className="all-users-table-container">
      {!isError ? (
        mappedValue ? (
          <DataTable
            columns={onBoardedCompanyTableHeaders}
            data={mappedValue}
            csvName="UserConfigurationSummaryDetails"
          />
        ) : (
          <TableLoader />
        )
      ) : (
        <Error type={errorResponse} />
      )}
    </div>
  );
}

export default UserManagementDataTable;
