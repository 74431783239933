import React from "react";
import MultiAttemptPA from "../../MultiAttemptPA/MultiAttemptPA";
import SettlementAccountPA from "../../SettlementAccountPA/SettlementAccountPA";
import TabComponentCardWrapper from "../../../../UI/TabComponentCardWrapper/TabComponentCardWrapper";

const SettlementAccountLayout = () => {
  return (
    <React.Fragment>
      <TabComponentCardWrapper
        heading=""
        tabs={[
          {
            className: "",
            label: "Multi Attempt",
            value: "multi-attempt",
            component: <MultiAttemptPA />,
          },
          {
            className: "",
            label: "Settlement Config",
            value: "settlement-config",
            component: <SettlementAccountPA />,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default SettlementAccountLayout;
