import React from "react";
import { matchSorter } from "match-sorter";
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import Pagination from "@material-ui/lab/Pagination";

// assets imports
import sort from "../../assets/sort-icon.svg";
import sortUp from "../../assets/sort-up-icon.svg";
import sortDown from "../../assets/sort-down-icon.svg";

// global search fuzzy field
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="data-table-global-filter">
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search in this table`}
      />
    </div>
  );
}

// data table  component
function Table({ columns, data, csvName }) {
  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // fuzzy search logic
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      // <input
      //   value={filterValue || ''}
      //   onChange={e => {
      //     setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      //   }}
      //   placeholder={`Search ${count} records...`}
      // />
      <></>
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // useTable Props for actions
  const props = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // props destructuring
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    state,
    page,
    preGlobalFilteredRows,
    pageOptions,
    gotoPage,
  } = props;

  // pagination UI styles
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));

  // pagination goTo Logic
  const classes = useStyles();
  const [paged, setPaged] = React.useState(1);
  const handleChange = (event, value) => {
    setPaged(value);
    gotoPage(value - 1);
  };

  // no match found message
  let noDataMessage;
  if (page.length === 0) {
    noDataMessage = (
      <div className="no-data__message">
        <p>No matching data found</p>
      </div>
    );
  }

  return (
    <div className="data-table-container">
      <div className="table-header">
        {/* <CSVLink
          className="csv-download"
          filename={!!csvName ? `${csvName}.csv` : "dataTableDetails.csv"}
          data={data}
        >
          Download
        </CSVLink> */}

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <div className="table-Container">
        <table className="rwd-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="rwd-table-header"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return column.hideHeader === false ? null : (
                    <>
                      <th
                        className={column.className}
                        {...column.getHeaderProps(
                          column.sort ? column.getSortByToggleProps() : ""
                        )}
                      >
                        <div style={{ textWrap: "nowrap" }}>
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          {!!column.sort ? (
                            <span className="header-text">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <div className="sort-icon">
                                    <img src={sortDown} alt="sort down" />
                                  </div>
                                ) : (
                                  <div className="sort-icon">
                                    <img src={sortUp} alt="sort up" />
                                  </div>
                                )
                              ) : (
                                <div className="sort-icon">
                                  <img src={sort} alt="sort" />
                                </div>
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* Render the columns filter UI */}
                        {/* <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div> */}
                      </th>
                    </>
                  );
                })}
              </tr>
            ))}

            {columns[0]?.showColumnFilter
              ? headerGroups.map((headerGroup) => (
                  <tr
                    className="rwd-table-header"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return column.hideHeader === false ? null : (
                        <th
                          className={"actions-header"}
                          {...column.getHeaderProps()}
                        >
                          {/* Render the columns filter UI */}
                          <div>
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))
              : null}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {noDataMessage}

      {/* pagination */}
      <div className={classes.root}>
        <Pagination
          count={pageOptions.length}
          page={paged}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default Table;
