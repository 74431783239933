import React, { useContext, useEffect, useState } from "react";
// component imports
import { APIConfig } from "../../../services/apiConfiguration";
import axios from "axios";
// context imports
import CallbackContext from "../../../contexts/CallbackContext";
import CallbackActionContext from "../../../contexts/CallbackActionContext";
// styles imports
import "./CallbackTypeDropdown.scss";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CallbackTypeDropdown = ({ setCallbackId, setCallbackTypeName }) => {
  const [selected, setSelected] = useState("Choose Callback Type");
  const [isActive, setIsActive] = useState(false);
  const [callbackType, setCallbackType] = useState("");

  const { resetCallback } = useContext(CallbackContext);
  const { tableEditData } = useContext(CallbackActionContext);

  useEffect(() => {
    setSelected("Choose Callback Type");
  }, [resetCallback]);

  useEffect(() => {
    if (tableEditData.edit) {
      setSelected(
        tableEditData?.item?.callbackType?.replace(/_/g, " ") ||
          "Choose Callback Type"
      );
    }
  }, [tableEditData]);

  const callbackList = () => {
    const check = localStorage.getItem("callbackType");

    if (check) {
      setCallbackType(JSON.parse(check));
    } else {
      APIConfig.API_Client.post(
        apiEndpointList.CALLBACK_TYPE.baseUrl +
          apiEndpointList.CALLBACK_TYPE.endpoint,
        // APIConfig.BASE_URL + "/frontend_sa/decentro/read/fetch/callback_type",
        { cancelToken: source.token }
      )
        .then((response) => {
          localStorage.setItem("callbackType", JSON.stringify(response.data));
          setCallbackType(response.data);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    callbackList();
  }, []);

  if (callbackType.length === 0) {
    return (
      <div className="callback-loader-skeleton">
        <span className="skeleton-loader"></span>
      </div>
    );
  }

  return (
    <div className={`dropdown-callback-type`}>
      <div className="dropdown-callback-label">Callback Type</div>
      <div
        className={`dropdown-btn ${
          selected === "Choose Callback Type" ? "text-light" : "text-dark"
        } ${isActive && " border-radius__invert"} 
        ${tableEditData.edit && "edit-content-disabled"}
        `}
        onClick={(e) => setIsActive(!isActive)}
      >
        {selected}
        {selected.length !== 1 && (
          <span className="dropdown-arrow">
            <DownArrow isActive={isActive} />
          </span>
        )}
      </div>
      <div id="callback-type__validation-message"></div>
      {isActive && (
        <div className={`callback-type-content`}>
          {callbackType.map((option) => (
            <div key={option.id}>
              {option.name.replaceAll("_", " ") !== selected && (
                <div
                  key={option.id}
                  onClick={(e) => {
                    setSelected(option.name.replaceAll("_", " "));
                    setIsActive(false);
                    setCallbackId(option.id);
                    setCallbackTypeName(option.name);
                  }}
                  className="dropdown-item"
                >
                  {option.name.replaceAll("_", " ")}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function DownArrow({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 dropdown-arrow ${
        isActive && "dropdown-arrow__rotate"
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default CallbackTypeDropdown;
