import React from "react";
// callback imports
import NewCallback from "../../NewCallbackConfiguration/NewCallback/NewCallback";
import NewCallbackFormik from "../../NewCallbackConfiguration/NewCallback/NewCallbackFormik";
// context imports
import { CallbackProvider } from "../../../contexts/CallbackContext";
// styles imports
import "./AddCallback.scss";

const AddCallback = () => {
  return (
    <React.Fragment>
      <div className="add-callback-page">
        <div className="add-callback-content">
          <div className="add-callback-card">
            <CallbackProvider>
              {/* <NewCallback /> */}
              <NewCallbackFormik />
            </CallbackProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddCallback;
