import React from "react";

//styles-import
import "./ComponentWrapper.scss";

const ComponentWrapper = ({
  heading,
  // * Passing headingHighlight as a company name in edit - to be highlighted in color
  headingHighlight = false,
  component,
  showBackBtn = false,
  backBtnCallback,
  isTable = false,
}) => {
  return (
    <React.Fragment>
      <div className="ui-component-page">
        <div
          className={
            isTable ? "table-component-content" : "ui-component-content"
          }
        >
          <div className="ui-component">
            <div className="ui-component-heading-wrapper">
              {showBackBtn ? (
                <img
                  onClick={backBtnCallback}
                  src="/images/back.svg"
                  alt="back-icon"
                />
              ) : null}
              {/* Heading */}
              <h1 className="heading">
                {heading}
                {headingHighlight ? (
                  <span className="highlighted-heading-text">
                    &nbsp;
                    {headingHighlight}
                  </span>
                ) : (
                  ""
                )}
              </h1>
            </div>
            {/* <div className="ui-divider"></div> */}
          </div>
          <div className="ui-component-form ui-divider">{component}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComponentWrapper;
