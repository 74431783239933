import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
// component styles
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import CloseIcon from "@material-ui/icons/Close";
import EditHttpDropdown from "../../EditCallbackModal/EditHttpDropdown/EditHttpDropdown";
import { EditTotpModal } from "../EditTotpModal/EditTotpModal";
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
// context imports
import CallbackContext from "../../../../contexts/CallbackContext";
// styles imports
import "./Modal.scss";
// Formik Imports
import { useFormik } from "formik";
import * as Yup from "yup";
// Utils
import { REGEXP } from "../../../../utilities/validators/inputValidators";
import { availbleHTTP } from "../NewCallbackFormik";
import { randomUUID } from "../../../../services/randomUUID";
import { isValidJSON } from "../../../../utilities/validations";
import paAPIEndpoints from "../../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

export const ModalFormik = () => {
  // ^ context
  const { setShowModal, editCallbackItem, editCallback, searchCompanyID } =
    useContext(CallbackContext);

  // * if user clicks on submit after changing callback, totpModal will open.
  const [isTotpModal, setIsTotpModal] = useState(false);
  //manage modal state
  const [openModal, setOpenModal] = useState(false);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Checking if there's a change.
  const [isChange, setIsChange] = useState(false);

  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  // input Fields
  const [callbackField, setCallbackField] = useState(
    editCallbackItem?.callbackType?.replaceAll("_", " ") || ""
  );
  const [headerField, setHeaderField] = useState(
    JSON.stringify(editCallbackItem?.subscriptionHeaders) || ""
  );
  const [urlField, setUrlField] = useState(
    editCallbackItem?.subscriberUrl || ""
  );
  // button validation
  const [isDisabled, setIsDisabled] = useState(true);

  // dropDown field
  const [httpId, setHttpId] = useState("");
  const [httpTypeName, setHttpTypeName] = useState(editCallbackItem.httpMethod);

  // validations
  const headerValidtion = useRef(false);
  const urlValidation = useRef(false);

  // autoFill fields side Effect
  useEffect(() => {
    setCallbackField(
      editCallbackItem?.callbackType?.replaceAll("_", " ") || ""
    );
    setHeaderField(JSON.stringify(editCallbackItem?.subscriptionHeaders) || "");
    setUrlField(editCallbackItem?.subscriberUrl || "");
    setHttpId(editCallbackItem?.httpMethod || "");
  }, [editCallbackItem]);

  // header InputHandler
  const headerInputHandler = (e) => {
    setHeaderField(e.target.value);
    try {
      JSON.parse(e.target.value);
      headerValidtion.current = false;
    } catch (e) {
      headerValidtion.current = true;
    }
  };

  // url InputHandler
  const urlInputHandler = (e) => {
    setUrlField(e.target.value);

    var urlRegExp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    if (urlRegExp.test(e.target.value)) {
      urlValidation.current = false;
    } else {
      urlValidation.current = true;
    }
  };

  // Btn validation side effect
  useEffect(() => {
    if (
      !!httpId &&
      // !!headerField &&
      // headerField.length !== 0 &&
      !!urlField &&
      urlField.length !== 0 &&
      // !headerValidtion.current &&
      !urlValidation.current
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [urlValidation, httpId, headerField, urlField]);

  // submit handler
  //   const submitHandler = () => {
  //     // let payload = {
  //     //   company_id: +editCallbackItem.companyId,
  //     //   subscriber_url: urlField,
  //     //   http_method: +httpId,
  //     //   subscription_protocol:
  //     //     urlField.indexOf("https://") === 0 ? "https" : "http",
  //     //   subscription_headers: JSON.parse(headerField),
  //     //   callback_type: editCallbackItem.callbackType,
  //     // };

  //     // ! updated api payload
  //     let newPayload = {
  //       reference_id: randomUUID(),
  //       company_id: +searchCompanyID,
  //       subscriber_url: urlField,
  //       http_method: httpTypeName, // https type name - new upload
  //       subscription_protocol:
  //         urlField.indexOf("https://") === 0 ? "https" : "http",
  //       subscription_headers: JSON.parse(headerField) || "",
  //       callback_type: editCallbackItem.callbackType, // callback type - updated payload
  //     };

  //     editCallback(newPayload);
  //   };

  // this will invoke to generate totp
  // const handleSubmit = () => {
  //   const companyInput = {
  //     source: "ADMIN",
  //     consent: true,
  //     reference_id: randomUUID(),
  //     google_user_token,
  //     email,
  //   };
  //   // * Generating OTP
  //   setIsLoading(true);
  //   APIConfig.API_Client.post(
  //     apiEndpointList.GENERATE_TOTP.baseUrl +
  //       apiEndpointList.GENERATE_TOTP.endpoint,
  //     companyInput
  //   )
  //     .then((res) => {
  //       setQrBase("");
  //       setIsLoading(false);
  //       if (res.status === 200) {
  //         if (res?.data?.data?.qrCode) {
  //           setQrBase(res.data.data.qrCode);
  //         } else {
  //           setQrBase("");
  //           setShowTotp(true);
  //         }
  //         //  Setting data to verify OTP
  //         setDataToVerify((prev) => ({
  //           ...prev,
  //           reference_id: companyInput.reference_id,
  //           initiation_decentro_txn_id: res.data.decentroTxnId,
  //         }));
  //       }
  //       // Open OTP Modal
  //       setIsTotpModal(true);
  //       setOpenModal(true);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       setIsLoading(false);
  //     });
  // };

  let initialValues = {
    // company_name: "",
    callback_type: callbackField,
    http_method: availbleHTTP?.value,
    subscription_headers_modal: headerField,
    subscription_protocol_modal: urlField,
    // transfer_type: "",
    // beneficiary_name: "",
  };

  const {
    nameRegex,
    ifscRegex,
    emailRegex,
    panRegex,
    urlRegex,
    phoneNumberRegex,
    accountNumberRegex,
  } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    // TODO: Not adding HTTP method here as it is currently being Hardcoded
    // company_name: Yup.mixed().required("Comapny Name is required"),
    callback_type: Yup.string().required("Callback Type is required"),
    http_method: Yup.string().required("HTTP Method is required"),
    subscription_headers_modal: Yup.string()
      .notRequired()
      .test(
        "is-valid-json",
        "Headers must be a valid JSON string",
        (value) => !value || isValidJSON(value)
      ),
    subscription_protocol_modal: Yup.string()
      .matches(urlRegex)
      .required("URL is required"),
  });

  const submitAfterTotp = (formik) => {
    const { values, action } = formik;
    let payload;
    if (values?.subscription_headers_modal) {
      payload = {
        reference_id: randomUUID(),
        company_id: +searchCompanyID,
        subscriber_url: values.subscription_protocol_modal,
        http_method: availbleHTTP?.value,
        subscription_protocol:
          values?.subscription_protocol_modal?.indexOf("https://") === 0
            ? "https"
            : "http",
        subscription_headers: JSON.parse(values.subscription_headers_modal),
        callback_type: values?.callback_type.replaceAll(" ", "_"),
      };
    } else {
      payload = {
        reference_id: randomUUID(),
        company_id: +searchCompanyID,
        subscriber_url: values.subscription_protocol_modal,
        http_method: availbleHTTP?.value,
        subscription_protocol:
          values?.subscription_protocol_modal?.indexOf("https://") === 0
            ? "https"
            : "http",
        callback_type: values?.callback_type.replaceAll(" ", "_"),
      };
    }
    editCallback(payload, action);
  };

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = () => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setIsTotpModal(true);
        setOpenModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      submitHandler(values, action);
      //   let payload;
      //   if (values?.subscription_headers_modal) {
      //     payload = {
      //       reference_id: randomUUID(),
      //       company_id: +searchCompanyID,
      //       subscriber_url: values.subscription_protocol_modal,
      //       http_method: availbleHTTP?.value,
      //       subscription_protocol:
      //         values?.subscription_protocol_modal?.indexOf("https://") === 0
      //           ? "https"
      //           : "http",
      //       subscription_headers: JSON.parse(values.subscription_headers_modal),
      //       callback_type: values?.callback_type.replaceAll(" ", "_"),
      //     };
      //   } else {
      //     payload = {
      //       reference_id: randomUUID(),
      //       company_id: +searchCompanyID,
      //       subscriber_url: values.subscription_protocol_modal,
      //       http_method: availbleHTTP?.value,
      //       subscription_protocol:
      //         values?.subscription_protocol_modal?.indexOf("https://") === 0
      //           ? "https"
      //           : "http",
      //       callback_type: values?.callback_type.replaceAll(" ", "_"),
      //     };
      //   }
      //   editCallback(payload, action);
    },
  });

  // checking if there is a change for the fields.
  const handleChange = (event) => {
    if (event.target.value === initialValues[event.target.name]) {
      setIsChange(false);
    } else {
      setIsChange(true);
    }
    formik.handleChange(event);
  };

  // render the modal JSX in the portal div (index.html).
  return ReactDom.createPortal(
    <>
      {/* Edit callback modal will only appear if "isTotpModal" is false */}
      {!isTotpModal && (
        <div className="container" ref={modalRef} onClick={closeModal}>
          <div className="modal">
            {/* close modal icon */}
            <button
              className="close-modal-btn"
              onClick={() => setShowModal(false)}
            >
              <CloseIcon />
            </button>
            {/* modal header */}
            <div className="edit-callback-heading">
              <p>Edit Callback</p>
            </div>
            <p className="edit-callback-subtitle">
              Here are some quick details you need to edit
            </p>

            <form onSubmit={formik.handleSubmit}>
              <div className="ui-form-details">
                <div className="ui-form-content">
                  <div className="ui-form-inputs-section mt-18">
                    <TextFieldInput
                      id="callback_type"
                      name="callback_type"
                      value={formik.values.callback_type}
                      onChange={formik.handleChange}
                      placeholder="Enter Callback Type"
                      label="Callback"
                      required={true}
                      disabled={true}
                      vertical={true}
                      marginTop={18}
                    />
                  </div>

                  <div className="ui-form-inputs-section mt-18">
                    <TextFieldInput
                      id="http_method"
                      name="http_method"
                      value={formik.values.http_method}
                      onChange={formik.handleChange}
                      placeholder="Enter HTTP Method"
                      label="HTTP Method"
                      required={true}
                      disabled={true}
                      vertical={true}
                    />
                  </div>

                  <div className="ui-form-inputs-section mt-18">
                    <TextFieldInput
                      id="subscription_headers_modal"
                      name="subscription_headers_modal"
                      value={formik.values.subscription_headers_modal}
                      touched={formik.touched.subscription_headers_modal}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.subscription_headers_modal}
                      placeholder={JSON.stringify({
                        key1: "value1",
                        key2: "value2",
                      })}
                      label="Header"
                      required={false}
                      vertical={true}
                    />
                  </div>

                  <div className="ui-form-inputs-section mt-18">
                    <TextFieldInput
                      id="subscription_protocol_modal"
                      name="subscription_protocol_modal"
                      value={formik.values.subscription_protocol_modal}
                      touched={formik.touched.subscription_protocol_modal}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.subscription_protocol_modal}
                      placeholder={"https://www.placeholder.com"}
                      label="URL"
                      required={true}
                      vertical={true}
                    />
                  </div>

                  <div className="ui-button-container">
                    <button
                      type="submit"
                      className={`submit-btn ${
                        formik.isValid &&
                        formik.dirty &&
                        isChange &&
                        !formik.isSubmitting
                          ? "active"
                          : ""
                      }`}
                      disabled={
                        !isChange || !formik.isValid || formik.isSubmitting
                      }
                    >
                      {formik.isSubmitting ? "Loading..." : "Submit"}
                      <span
                        id="user-config-loader"
                        style={{ display: "flex" }}
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </form>

            {/* modal fields */}
            {/* <div className="edit-callback-fields"> */}
            {/* callback input */}
            {/* <div className="text-input-wrapper">
                                <div className="text-input-label">Callback</div>
                                <div className={`add-callback-input callback-read-only`}>
                                    <input
                                        type="text"
                                        value={callbackField}
                                        placeholder="Enter Callback"
                                        className="callback-field-input callback-default-filled"
                                        readOnly
                                    />
                                </div>
                            </div> */}

            {/* http dropdown */}
            {/* <div className="dropdown-input-wrapper">
                                <div className="http-input-label">Http</div>
                                <EditHttpDropdown
                                    setHttpId={setHttpId}
                                    setHttpTypeName={setHttpTypeName}
                                    editData={editCallbackItem}
                                />
                            </div> */}

            {/* header input */}
            {/* <div className="text-input-wrapper">
                                <div className="text-input-label-without-required">Header</div>
                                <div
                                    className={`add-callback-input ${headerValidtion.current === true && "error-border"
                                        } `}
                                >
                                    <input
                                        type="text"
                                        placeholder={JSON.stringify({
                                            key1: "value1",
                                            key2: "value2",
                                        })}
                                        value={headerField}
                                        className="callback-field-input"
                                        onChange={headerInputHandler}
                                    />
                                </div>
                            </div> */}

            {/* url input */}
            {/* <div className="text-input-wrapper">
                                <div className="text-input-label">Url</div>
                                <div
                                    className={`add-callback-input ${urlValidation.current === true && "error-border"
                                        } `}
                                >
                                    <input
                                        type="text"
                                        placeholder={"https://www.placeholder.com"}
                                        value={urlField}
                                        className="callback-field-input"
                                        onChange={urlInputHandler}
                                    />
                                </div>
                            </div>
                        </div> */}

            {/* update callback btn */}
            {/* <div className="edit-button-wrapper">
                            <button
                                className={`update-callback-details ${!isDisabled && "active-update-btn"
                                    }`}
                                onClick={() => {
                                    // ! With TOTP
                                    // handleSubmit();
                                    // ! W/O TOTP
                                    submitHandler();
                                }}
                                type="button"
                                disabled={isDisabled}
                            >
                                Submit
                            </button>
                        </div> */}
          </div>
        </div>
      )}

      {/* EditTotp modal will open after generating TOTP from above modal*/}
      {isTotpModal && (
        <EditTotpModal
          setIsTotpModal={setIsTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitAfterTotp={(formik) => submitAfterTotp(formik)}
          formik={formik}
        />
      )}
    </>,
    document.getElementById("portal")
  );
};
