import React, { useContext } from "react";
//context import
import DashboardUserPAContext from "../../contexts/DashboardUserPAContext/DashboardUsersContext";
import ComponentWrapper from "../../UI/ComponentWrapper/ComponentWrapper";
//component for edit and update
import TabHeading from "../../UI/TabHeading/TabHeading";
import UserManagementDataTable from "../UserManagementDataTable/UserManagementDataTable";
import AddNewUser from "./AddNewUser";
import EditNewUser from "./EditNewUser";
// style imports
import "./UserManagementPA.scss";

function UserManagementPA() {
  // ^ context
  const { cardType, userSummary } = useContext(DashboardUserPAContext);

  const USER_MANAGEMENT_CARD = {
    addUser: (
      <ComponentWrapper
        heading="Add User"
        showBackBtn={true}
        backBtnCallback={userSummary}
        component={<AddNewUser />}
      />
    ),
    editUser: (
      <ComponentWrapper
        heading="Edit User"
        showBackBtn={true}
        backBtnCallback={userSummary}
        component={<EditNewUser />}
      />
    ),
    userSummary: <UserManagementSummaryCard />,
  };

  return (
    <div>
      <div>{USER_MANAGEMENT_CARD[cardType]}</div>
    </div>
  );
}
const UserManagementSummaryCard = () => {
  const { addUser } = useContext(DashboardUserPAContext);

  const addNewUser = () => {
    addUser();
  };

  return (
    <div className="user-management-page">
      <div className="user-management-content">
        <div className="user-management">
          <TabHeading headingText="Dashboard Users" />

          <button
            onClick={addNewUser}
            className={`configure-user-btn`}
            type="button"
          >
            <span>+</span> Add User
          </button>
        </div>
        <div className="user-management-card">
          <UserManagementDataTable />
        </div>
      </div>
    </div>
  );
};

export default UserManagementPA;
