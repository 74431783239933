import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Formik Imports
import { useFormik } from "formik";
import * as Yup from "yup";
// Components
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import Divider from "../../../UI/Divider/Divider";
import { Modal } from "./Modal/Modal";
import { ModalFormik } from "./Modal/ModalFormik";
import ListCallback from "../ListCallback/ListCallback";
// Utils
import { REGEXP } from "../../../utilities/validators/inputValidators";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
import { isValidJSON } from "../../../utilities/validations";
import { randomUUID } from "../../../services/randomUUID";
// Axios Imports
import Axios from "axios";
// Contexts
import CallbackActionContext from "../../../contexts/CallbackActionContext";
import CallbackContext from "../../../contexts/CallbackContext";
import { TotpModal } from "./TotpModal/TotpModal";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// TODO: as there only available http so hardcoding it, ideally this should come from BE and displayed in dropdown.
export const availbleHTTP = {
  value: "POST",
  id: 3,
};

const NewCallbackFormik = () => {
  const {
    addCallbackApi,
    editCallback,
    showCallbackList,
    showModal,
    setListIds,
    showTotpModal,
    setShowTotpModal,
    openTotpModal,
    setSearchCompanyID,
    currentModal,
    setCurrentModal,
  } = useContext(CallbackContext);
  const { tableEditData, allCallback } = useContext(CallbackActionContext);

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const [callbackList, setCallbackList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [httpMethods, setHttpMethods] = useState(availbleHTTP);

  const [companiesList, setCompaniesList] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);

  // if callback exist
  const [callbackExist, setCallbackExist] = useState(false);

  let initialValues = {
    company_name: "",
    callback_type: "",
    http_method: "",
    subscription_headers: "",
    subscription_protocol: "",
  };

  const {
    nameRegex,
    ifscRegex,
    emailRegex,
    panRegex,
    urlRegexCallback,
    phoneNumberRegex,
    accountNumberRegex,
  } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    // TODO: Not adding HTTP method here as it is currently being Hardcoded
    company_name: Yup.mixed().required("Company Name is required"),
    callback_type: Yup.mixed().required("Callback Type is required"),
    subscription_headers: Yup.string()
      .notRequired()
      .test(
        "is-valid-json",
        "Headers must be a valid JSON string",
        (value) => !value || isValidJSON(value)
      ),
    subscription_protocol: Yup.string()
      .matches(urlRegexCallback)
      .required("URL is required"),
  });

  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = () => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
        setCurrentModal("NewCallback"); // Set the current modal to 'NewCallback'
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const submitCallback = (formik) => {
    const { values, action } = formik;
    let payload;
    if (values.subscription_headers) {
      payload = {
        reference_id: randomUUID(),
        company_id: +values.company_name?.value,
        subscriber_url: values.subscription_protocol,
        http_method: availbleHTTP?.value,
        subscription_protocol:
          values.subscription_protocol.indexOf("https://") === 0
            ? "https"
            : "http",
        subscription_headers: JSON.parse(values.subscription_headers),
        callback_type: values.callback_type.value,
      };
    } else {
      payload = {
        reference_id: randomUUID(),
        company_id: +values.company_name?.value,
        subscriber_url: values.subscription_protocol,
        http_method: availbleHTTP?.value,
        subscription_protocol:
          values.subscription_protocol.indexOf("https://") === 0
            ? "https"
            : "http",
        callback_type: values.callback_type.value,
      };
    }
    addCallbackApi(payload, action, formik);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      submitHandler(values, action);
      // let payload;
      // if (values.subscription_headers) {
      //   payload = {
      //     reference_id: randomUUID(),
      //     company_id: +values.company_name?.value,
      //     subscriber_url: values.subscription_protocol,
      //     http_method: availbleHTTP?.value,
      //     subscription_protocol:
      //       values.subscription_protocol.indexOf("https://") === 0
      //         ? "https"
      //         : "http",
      //     subscription_headers: JSON.parse(values.subscription_headers),
      //     callback_type: values.callback_type.value,
      //   };
      // } else {
      //   payload = {
      //     reference_id: randomUUID(),
      //     company_id: +values.company_name?.value,
      //     subscriber_url: values.subscription_protocol,
      //     http_method: availbleHTTP?.value,
      //     subscription_protocol:
      //       values.subscription_protocol.indexOf("https://") === 0
      //         ? "https"
      //         : "http",
      //     callback_type: values.callback_type.value,
      //   };
      // }
      // addCallbackApi(payload, action, formik);
    },
  });

  //  TODO: Shift in utilities.
  // converting data coming from API to CamelCase, e.g: "payment_links" to "Payment Links"
  function formatString(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Fetching the Callback Lists to show in dropdown.
  const callbackLists = (companyID) => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GET_CALLBACK_TYPE.baseUrl +
        apiEndpointList.GET_CALLBACK_TYPE.endpoint,
      { company_id: companyID },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.name,
          label: formatString(item.name),
          callbackId: item.id,
        }));
        setIsLoading(false);
        setCallbackList(options);
      })
      .catch((error) => {
        console.error("error while fetching callbackLists :", error);
        setIsLoading(false);
        setCallbackList([]);
      });
  };

  // Fetching Companies to show in dropdown
  const getCompanyDetail = () => {
    setCompaniesLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setCompaniesLoading(false);
        // * Response casing change functionality
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.company_id,
          label: item.common_name,
        }));
        // const responseToCamelCase = snakeToCamelCase(response.data.data);

        setCompaniesList(options);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompaniesLoading(false);
        setCompaniesList([]);
      });
  };

  useEffect(() => {
    getCompanyDetail();
  }, []);

  const backToAllCallback = () => {
    allCallback();
  };

  useEffect(() => {
    if (formik.values.company_name?.value) {
      callbackLists(formik.values.company_name?.value);
    }
  }, [formik.values.company_name?.value]);

  // callback already exist api check
  const callbackExistCheckApi = () => {
    APIConfig.API_Client.get(
      // APIConfig.BASE_URL +
      apiEndpointList.GET_CALLBACK_DETAILS.baseUrl +
        apiEndpointList.GET_CALLBACK_DETAILS.endpoint +
        "/" +
        `${formik?.values?.company_name?.value}/${formik?.values?.callback_type?.callbackId}`,
      { cancelToken: source.token }
    )
      .then((response) => {
        if (Object.keys(response.data.data).length) {
          setCallbackExist(true);
          ReactDOM.render(
            <div className="exist-callback__message">
              <p>Callback Already exist. Choose another callback !</p>
            </div>,
            document.getElementById("callback-type__validation-message")
          );
        } else {
          ReactDOM.unmountComponentAtNode(
            document.getElementById("callback-type__validation-message")
          );
          setCallbackExist(false);
        }
      })
      .catch((error) => {});
  };

  // call exist side effect
  useEffect(() => {
    if (
      !!formik?.values?.company_name?.value &&
      !!formik?.values?.callback_type?.callbackId &&
      !tableEditData.edit
    ) {
      callbackExistCheckApi();
    } else {
      setCallbackExist(false);
    }
  }, [formik?.values?.company_name, formik?.values?.callback_type]);

  return (
    <>
      <div className="callback-container">
        <div onClick={backToAllCallback} className="header-container">
          <img src="/images/back.svg" alt="back-icon" />
          <h1 className="heading">Configure New Callback</h1>
        </div>
      </div>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section mt-0">
              <TextFieldSelect
                id="company_name"
                name="company_name"
                onChange={(selectedOption) => {
                  formik.setFieldValue("company_name", selectedOption);
                  if (selectedOption !== null) {
                    setSearchCompanyID(selectedOption?.company_id);
                    setListIds({
                      companyId: selectedOption.company_id,
                      commonName: selectedOption.common_name,
                    });
                  }
                }}
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companiesList}
                noOptionsMessage={() => "No Company Name Exists"}
                label="Company Name"
                required={true}
                isLoading={companiesLoading}
                placeholder="Choose Company Name"
                isformatOptionLabel={true}
              />
            </div>
            <Divider />
            <div className="ui-form-inputs-section mt-0 postion-r">
              <TextFieldSelect
                id="callback_type"
                name="callback_type"
                onChange={(selectedOption) => {
                  formik.setFieldValue("callback_type", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("callback_type", true)}
                value={formik.values.callback_type}
                options={callbackList}
                noOptionsMessage={() => "No Callback Type Exists"}
                label="Callback Type"
                required={true}
                isLoading={isLoading}
                placeholder="Choose Callback Type"
                isClearable={false}
                isformatOptionLabel={false}
              />
              {callbackExist && (
                <div
                  id="callback-type__validation-message"
                  className="callback-exist-error"
                ></div>
              )}

              <TextFieldInput
                id="http_method"
                name="http_method"
                value={httpMethods.value}
                placeholder="Enter HTTP Method"
                label="HTTP Method"
                required={true}
                disabled={true}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="subscription_headers"
                name="subscription_headers"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subscription_headers}
                touched={formik.touched.subscription_headers}
                error={formik.errors.subscription_headers}
                placeholder={JSON.stringify({
                  key1: "value1",
                  key2: "value2",
                })}
                label="Header"
                required={false}
                disabled={false}
                isToolTip={`Example: ${JSON.stringify({
                  ContentType: "application/json",
                  abc: "xxxxxxx",
                })}`}
              />
              <TextFieldInput
                id="subscription_protocol"
                name="subscription_protocol"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subscription_protocol}
                touched={formik.touched.subscription_protocol}
                error={formik.errors.subscription_protocol}
                placeholder={"https://www.placeholder.com"}
                label="URL"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                className={`submit-btn ${
                  !callbackExist &&
                  formik.isValid &&
                  formik.dirty &&
                  !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                disabled={
                  callbackExist || !formik.isValid || formik.isSubmitting
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* TOTP modal */}
      {showTotpModal && currentModal === "NewCallback" && (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={(formik) => submitCallback(formik)}
          formik={formik}
        />
      )}

      {/* callback list */}
      {showCallbackList && formik?.values?.company_name?.value && (
        <>
          <Divider />
          <ListCallback
            currentModal={currentModal}
            setCurrentModal={setCurrentModal}
          />
        </>
      )}

      {showModal ? <ModalFormik /> : null}
    </>
  );
};

export default NewCallbackFormik;
