import React from "react";

//styles-import
import "./FormHeading.scss";

const FormHeading = ({ headingText }) => {
  return (
    <div className="ui-form-heading">
      <h2>{headingText}</h2>
    </div>
  );
};

export default FormHeading;
