import React from "react";

//components-import
import CopyChip from "../../../../UI/CopyChip/CopyChip";


// utils-import
import getSelectedProperties from "../../../../utilities/getSelectedProperties";

//styles-import
import "./VPAResponseDetails.scss";

const VPAResponseDetails = ({ data, onClick }) => {
  const status = data.api_status === "FAILURE" ? "failure" : "success";
  const imgSrc =
    status === "failure"
      ? "/images/onfailure-error.svg"
      : "/images/onsuccess-tick.svg";

  const desiredProperties = ['decentro_txn_id', 'message', 'api_status'];

  const selected = getSelectedProperties(data, desiredProperties) || "-"
  const copyableData = selected

  return (
    <>
      <div className="response-details-container">
        <h4 className="response-details-heading">
          <img
            src={imgSrc}
            className={`${status}-status-icon`}
            alt="status-icon"
          />
          {status === "failure" ? "Failure" : "Success"} Response Details
          <div className="right-icons-container">
            <CopyChip
              label="Copy All"
              color="primary"
              variant="outlined"
              textToCopy={copyableData !== null ? copyableData : "-"}
              iconSrc="/images/copy-icon.svg"
            />
            <img
              src="/images/close.svg"
              className="close-response-details"
              alt="close"
              onClick={onClick}
            />
          </div>

        </h4>
        <div className="response-details-divider"></div>
        <div className="response-details">
          <ul className="response-list">
            {data?.decentro_txn_id && (
              <li className="response-list-item">
                <span className="field">Decentro Transaction ID: </span>
                <span className="value">{data?.decentro_txn_id || "-"}</span>
              </li>
            )}
            {data?.message && (
              <li className="response-list-item">
                <span className="field">Message: </span>
                <span className="value">{data?.message || "-"}</span>
              </li>
            )}
            {data?.api_status && (
              <li className="response-list-item">
                <span className="field">Status: </span>
                <span className="value">{data?.api_status || "-"}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default VPAResponseDetails;
