import React from "react";

//styles-import
import "./FormTitle.scss";

const FormTitle = ({ titleText }) => {
  return (
    <div className="ui-form-title">
      <h3>{titleText}</h3>
    </div>
  );
};

export default FormTitle;
