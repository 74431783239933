import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
//styles-import
import { makeStyles } from "@material-ui/core/styles";
import { DateRangePicker } from "rsuite";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useFilters,
} from "react-table";
// assets imports
import sort from "../../assets/sort-icon.svg";
import sortUp from "../../assets/sort-up-icon.svg";
import sortDown from "../..//assets/sort-down-icon.svg";
//components-import
import TableLoader from "../../components/Shared/TableLoader/TableLoader";
import SnackbarMessage from "../../components/SnackbarMessage/SnackbarMessage";
import Pagination from "@material-ui/lab/Pagination";
import Select from "react-select";
//API-imports
import axios from "axios";
import HandleOutsideClick from "../HandleOutsideClick/HandleOutsideClick";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
// styles-import
// import "rsuite/dist/rsuite-no-reset.min.css";
import "./AdvanceDataTable.scss";

const { afterToday } = DateRangePicker;

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #cbcbcb",
    borderRadius: "18px",
    "&:hover": {
      borderColor: "#0092ff",
      cursor: "pointer",
    },
    fontSize: "14px",
  }),

  placeholder: (base) => ({
    ...base,
    fontSize: "13px",
  }),

  option: (provided) => ({
    ...provided,
    fontSize: "14px",
    color: "#333",
  }),
};

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// data table  component
function Table({ columns, companyOptions, isOptionsLoading }) {
  //table data states
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //pagination states
  const limit = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [paged, setPaged] = React.useState(1);

  //filter select states
  const [selectedCompanyNames, setSelectedCompanyNames] = React.useState("");
  const [selectedDateRange, setSelectedDateRange] = React.useState([]);

  //filter value states
  const [settlementURNFilterValue, setSettlementURNFilterValue] = useState();
  const [companyIdFilterValue, setCompanyIdFilterValue] = useState();
  const [decentroTxID, setDecentroTxID] = useState();
  const [startDateFilterValue, setStartDateFilterValue] = useState();
  const [endDateFilterValue, setEndDateFilterValue] = useState();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentSearchScope, setCurrentSearchScope] = useState(false);
  const [searchReadOnly, setSearchReadOnly] = useState(true);

  const getRecordsCount = (filters = {}) => {
    const {
      chargeback_utr,
      company_list,
      chargeback_type_list,
      start_date,
      end_date,
      last_date_for_resolution,
      chargeback_urn,
    } = filters;

    APIConfig.API_Client.post(
      apiEndpointList.CHARGEBACK_COUNT.baseUrl +
      apiEndpointList.CHARGEBACK_COUNT.endpoint,
      {
        company_list,
        chargeback_type_list,
        start_date,
        end_date,
        chargeback_utr,
        last_date_for_resolution,
        chargeback_urn,
      },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const count = response.data[0]["count(*)"];
        const pageCount = Math.ceil((count * 1.0) / limit);
        setTotalPages(pageCount);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to fetch chargeback records count"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  const chargebackData = (page = 1, filters = {}) => {
    const offset = (page - 1) * limit;
    const {
      company_id,
      settlement_urn,
      decentro_transaction_id,
      start_date,
      end_date,
    } = filters;

    let payload = {
      offset,
      limit,
    };
    if (company_id) payload.company_id = company_id;
    if (settlement_urn) payload.settlement_urn = settlement_urn;
    if (decentro_transaction_id)
      payload.decentro_transaction_id = decentro_transaction_id;
    if (start_date) payload.start_date = start_date;
    if (end_date) payload.end_date = end_date;

    APIConfig.API_Client.post(
      apiEndpointList.SETTLEMENT_HISTORY.baseUrl +
      apiEndpointList.SETTLEMENT_HISTORY.endpoint,
      payload,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setData(response.data);
        const pageCount = Math.ceil((response?.data?.length * 1.0) / limit);
        setTotalPages(pageCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error from AdvanceDataTable,", error);
        setData([]);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to fetch chargeback data"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    chargebackData();
  }, []);

  // useTable Props for actions
  const props = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // props destructuring
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
  } = props;

  // pagination UI styles
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();

  const filters = {
    company_id: companyIdFilterValue,
    settlement_urn: settlementURNFilterValue,
    decentro_transaction_id: decentroTxID,
    start_date: startDateFilterValue,
    end_date: endDateFilterValue,
  };

  // pagination logic
  const handleChange = (event, value) => {
    setPaged(value);
    chargebackData(value, filters);
    gotoPage(value - 1);
  };

  //no match found message
  let noDataMessage;
  if (page.length === 0) {
    noDataMessage = (
      <div className="no-data-message">
        <p>No matching data found</p>
      </div>
    );
  }

  const findISODate = (date) => {
    let ISODate = new Date(date);
    const offset = ISODate.getTimezoneOffset();
    ISODate = new Date(ISODate.getTime() - offset * 60 * 1000);
    return ISODate;
  };

  const handleDateChange = (dateRange) => {
    setSelectedDateRange(dateRange);
    let endDate = dateRange != null ? new Date(dateRange[1]) : [];
    endDate =
      dateRange != null ? new Date(endDate.getTime() + 1 * 86400000) : "";

    const startDate =
      dateRange != null
        ? findISODate(dateRange[0]).toISOString().split("T")[0]
        : "";
    endDate =
      dateRange != null ? findISODate(endDate).toISOString().split("T")[0] : "";
    setIsFilterApplied(true);
    filters.start_date = startDate;
    filters.end_date = endDate;
    chargebackData(1, filters);
    setPaged(1);
    setStartDateFilterValue(startDate);
    setEndDateFilterValue(endDate);
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    setIsFilterApplied(true);
    const val = e.target.value.length > 0 ? e.target.value : "";
    if (currentSearchScope === "Settlement URN") {
      filters.settlement_urn = val;
      setSettlementURNFilterValue(val);
    }

    if (currentSearchScope === "Decentro Transaction ID") {
      filters.decentro_transaction_id = val;
      setDecentroTxID(val);
    }
    chargebackData(1, filters);
    setPaged(1);
  };

  const ToggleSearchDropdown = () => {
    document
      .querySelector(".advanced-search-option")
      .classList.toggle("active-cv");
    document
      .querySelector(".search-filter-wrapper")
      .classList.toggle("show-border-cv");
  };
  const getCellStyle = (value) => {
    if (typeof value !== 'string') return {};

    const statusColorMap = {
      green: ['success'],
      red: ['failed'],
      yellow: ['initiated', 'accepted', 'hold']
    };

    const colorStyles = {
      green: '#7EC683',
      red: '#E06060',
      yellow: '#E6AC3D'
    };

    const lowerValue = value.toLowerCase();

    for (const [color, statuses] of Object.entries(statusColorMap)) {
      if (statuses.includes(lowerValue)) {
        return { color: colorStyles[color], fontWeight: "600" };
      }
    }

    return {};
  };

  const searchContainerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      document.querySelector(".advanced-search-option").classList.remove("active-cv");
      document.querySelector(".search-filter-wrapper").classList.remove("show-border-cv");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {!isLoading || isFilterApplied ? (
        <div className="chargeback-data-table-container">
          <div className="chargeback-table-header">
            <div className="chargeback-data-table-filter">
              <div className="company-name-selector">
                <Select
                  isClearable
                  isSearchable
                  isLoading={isOptionsLoading}
                  options={companyOptions}
                  value={selectedCompanyNames}
                  onChange={(selectedOption) => {
                    setSelectedCompanyNames(selectedOption);
                    setIsFilterApplied(true);
                    filters.company_id = selectedOption?.value;
                    chargebackData(1, filters);
                    setPaged(1);
                    setCompanyIdFilterValue(selectedOption?.value);
                  }}
                  placeholder="Select Company Name"
                  styles={customStyles}
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="search-container" ref={searchContainerRef}>
                <div className="search-filter-wrapper">
                  <div className="advanced-search-container">
                    <input
                      type="search"
                      value={searchValue}
                      onChange={handleSearchValueChange}
                      placeholder={` ${currentSearchScope ? currentSearchScope : "Search"}`}
                      readOnly={searchReadOnly}
                      className={
                        currentSearchScope === false ? "cursor-pointer" : ""
                      }
                      onClick={
                        currentSearchScope === false
                          ? ToggleSearchDropdown
                          : null
                      }
                    />
                    {searchValue.length === 0 && (
                      <img
                        className="downward-icon"
                        src="/images/arrow-downward.svg"
                        alt="Arrow Down"
                        onClick={ToggleSearchDropdown}
                      />
                    )}
                  </div>
                  <div className="advanced-search-option">
                    <div className="options">
                      <div>
                        <div
                          onClick={() => {
                            document
                              .querySelector(".advanced-search-option")
                              .classList.remove("active-cv");
                            setCurrentSearchScope("Settlement URN");
                            document
                              .querySelector(".search-filter-wrapper")
                              .classList.toggle("show-border-cv");
                            setSearchReadOnly(false);
                          }}
                          className="option-cv"
                        >
                          Settlement URN
                        </div>
                      </div>
                      <div>
                        <div
                          onClick={() => {
                            document
                              .querySelector(".advanced-search-option")
                              .classList.remove("active-cv");
                            setCurrentSearchScope("Decentro Transaction ID");
                            document
                              .querySelector(".search-filter-wrapper")
                              .classList.toggle("show-border-cv");
                            setSearchReadOnly(false);
                          }}
                          className="option-cv"
                        >
                          Decentro Transaction ID
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <DateRangePicker
                appearance="default"
                placeholder="Select Date Range"
                value={selectedDateRange}
                onChange={handleDateChange}
                className="date-range-picker"
                shouldDisableDate={afterToday()}
              /> */}
            </div>
          </div>

          <div className="chargeback-table-container">
            <table className="rwd-chargeback-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="rwd-chargeback-header"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return column.hideHeader === false ? null : (
                        <>
                          <th
                            className={column.className}
                            {...column.getHeaderProps(
                              column.sort ? column.getSortByToggleProps() : ""
                            )}
                          >
                            <div style={{ textWrap: "nowrap" }}>
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              {!!column.sort ? (
                                <span className="header-text">
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <div className="sort-icon">
                                        <img src={sortDown} alt="sort down" />
                                      </div>
                                    ) : (
                                      <div className="sort-icon">
                                        <img src={sortUp} alt="sort up" />
                                      </div>
                                    )
                                  ) : (
                                    <div className="sort-icon">
                                      <img src={sort} alt="sort" />
                                    </div>
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            {/* Render the columns filter UI */}
                            {/* <div>
          {column.canFilter ? column.render("Filter") : null}
        </div> */}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                ))}

                {columns[0]?.showColumnFilter
                  ? headerGroups.map((headerGroup) => (
                    <tr
                      className="rwd-chargeback-table-header"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => {
                        return column.hideHeader === false ? null : (
                          <th
                            className={"actions-header"}
                            {...column.getHeaderProps()}
                          >
                            {/* Render the columns filter UI */}
                            <div>
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))
                  : null}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={getCellStyle(cell.value)}
                          >
                            {cell.value === null ? "-" : cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {noDataMessage}
          </div>

          {/* pagination */}
          {page.length > 0 ? (
            <div className={classes.root}>
              <Pagination
                count={totalPages}
                page={paged}
              // onChange={handleChange}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <TableLoader />
      )}
    </>
  );
}

export default Table;

// Line 551:chargeback-table-header after
