/* eslint-disable react/react-in-jsx-scope */
import React, { useRef, useState } from "react";
import ReactDom from "react-dom";

// API config imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import { randomUUID } from "../../../../services/randomUUID";

// component imports
import CloseIcon from "@material-ui/icons/Close";

// styles import
import "./TotpModal.scss";
import paAPIEndpoints from "../../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

export const TotpModal = ({
  setShowTotpModal,
  showTotp,
  setShowTotp,
  dataToVerify,
  qrBase,
  submitCallback,
  formData,
  formik,
}) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowTotpModal(false);
      formik.setSubmitting(false);
    }
  };

  // TOTP Component
  const TotpModal = () => {
    const [otpValue, setOtpValue] = useState("");
    const [otpInvalid, setOtpInvalid] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const otpInputHandler = (e) => {
      // Restrict input to numeric characters only
      const numericInput = e.target.value.replace(/\D/, "");
      setOtpValue(numericInput);
      setOtpInvalid(false);
      if (e.target.value.length > 5) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    };

    const submitHandler = (e) => {
      e.preventDefault();
      const payloadData = {
        ...dataToVerify,
        reference_id: randomUUID(),
        totp: otpValue,
      };

      APIConfig.API_Client.post(
        paAPIEndpoints.VERIFY_TOTP.baseUrl +
          paAPIEndpoints.VERIFY_TOTP.endpoint,
        payloadData
      )
        .then((res) => {
          if (res.status === 200 && res.data.response_key == "success_verify") {
            if (res.data.response_message == "Verify OTP Successful") {
              if (formData) {
                submitCallback(formData, formik);
              } else {
                submitCallback();
              }
              setShowTotpModal(false);
            }
          } else {
            setOtpInvalid(true);
          }
        })
        .catch((e) => {
          console.error("Error in API request:", e);
          if (e.response) {
            console.error("Response data:", e.response.data);
          }
          setOtpInvalid(true);
        });
    };

    return (
      <>
        <form onSubmit={submitHandler}>
          <div className="totp-heading">
            <p>Validate TOTP</p>
          </div>
          <p className="sub-heading-totp">
            Enter the TOTP received on your device
          </p>
          <div className="edit-credit-fields">
            <div className="text-input-wrapper">
              <div className="text-input-label">Google Auth TOTP</div>
              <div className="add-totp-input">
                <input
                  value={otpValue}
                  type="text"
                  placeholder="Enter OTP"
                  className="totp-field-input"
                  onChange={otpInputHandler}
                  maxLength={6}
                />
              </div>
            </div>
          </div>

          {otpInvalid && (
            <div className="error-response-block">
              Invalid authentication_token or operations access not allowed
            </div>
          )}
          <div className="totp-button-wrapper">
            <button
              disabled={isDisabled}
              className={`update-credit-btn ${
                !isDisabled && "active-update-btn"
              }`}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  };

  const QRModal = () => {
    return (
      <>
        <div className="totp-heading">
          <h3>Scan the code for the Google Auth Setup</h3>
        </div>
        <div className="qr-container">
          <img
            className="qr-OTP"
            src={`data:image/png;base64, ${qrBase}`}
            alt="qr sample"
          />
        </div>
        <div className="totp-button-wrapper">
          <button
            className="update-credit-btn active-update-btn"
            onClick={() => setShowTotp(true)}
            type="button"
          >
            Done
          </button>
        </div>
      </>
    );
  };

  return ReactDom.createPortal(
    <div
      className="all-callbacks-container"
      ref={modalRef}
      onClick={closeModal}
    >
      <div className="modal">
        <button
          className="close-modal-btn"
          onClick={() => {
            setShowTotpModal(false);
            formik.setSubmitting(false);
          }}
        >
          <CloseIcon />
        </button>
        {/* Only when showTOTP is true and qrbase is not a empty string(inital state) 'QR modal' is displayed. */}
        {!showTotp && qrBase.length > 0 && <QRModal />}
        {showTotp && <TotpModal />}
      </div>
    </div>,
    document.getElementById("portal")
  );
};
