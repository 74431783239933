import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Styling Imports
import "./ManageFunds.scss";
// Utility Imports
import { REGEXP } from "../../../utilities/validators/inputValidators";
// Form and Validation Libraries
import { useFormik } from "formik";
import * as Yup from "yup";
// Axios
import axios from "axios";
// UI Components
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import { TotpModal } from "../TotpModal/TotpModal";
// API and Configuration
import { APIConfig } from "../../../services/apiConfiguration";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
import { randomUUID } from "../../../services/randomUUID";

const provider = {
  bank_id: 1,
  bank_name: "Yes Bank",
  bank_code: "yesb",
};

let actionDropDownValues = [
  {
    value: "UNLOAD",
    label: "UNLOAD",
  },
];

const initialValues = {
  // action: actionDropDownValues[0],
  transfer_type: "",
  account_number: "",
  counter_party_account_number: "",
  amount: "",
  counter_party_ifsc_code: "",
  reference_id: "",
  purpose_message: "",
  provider_code: provider.bank_code,
  bank_reference_number: "",
};

const {
  laAccountRegex,
  ifscRegex,
  referenceIdRegex,
  amountRegex,
  bankReferenceNumber,
} = REGEXP;

const validationSchema = Yup.object({
  // action: Yup.object().shape({
  //     value: Yup.string().required("Please select an option"),
  //     label: Yup.string().required("Please select an option"),
  // }),
  transfer_type: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  account_number: Yup.string()
    .required("Account Number is required")
    .matches(laAccountRegex, "Invalid Account Number"),
  counter_party_account_number: Yup.string()
    .required("Counter Party Account Number is required")
    .matches(laAccountRegex, "Invalid Account Number"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(amountRegex, "Invalid Amount"),
  counter_party_ifsc_code: Yup.string()
    .required("Counter Party IFSC is required")
    .matches(ifscRegex, "Invalid IFSC"),
  reference_id: Yup.string()
    .required("Reference ID is required")
    .matches(referenceIdRegex, "Invalid Reference ID")
    .min(5, "Reference ID must be at least 5 characters")
    .max(50, "Reference ID can be max 50 characters"),
  purpose_message: Yup.string()
    .required("Purpose Message is required")
    .min(5, "Purpose Message must be at least 5 characters")
    .max(50, "Purpose Message can be max 50 characters"),
  // * Commenting this as it is currently being hardcoded on FE.
  // provider_code: Yup.object().shape({
  //     value: Yup.string().required("Please select an option"),
  //     label: Yup.string().required("Please select an option"),
  // }),
  bank_reference_number: Yup.string()
    .required("Bank Reference Number is required")
    .matches(bankReferenceNumber, "Invalid Bank Reference Number")
    .min(5, "Bank Reference Number must be at least 5 characters")
    .max(50, "Bank Reference Number can be max 50 characters"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ManageFunds = () => {
  const [transferTypes, setTransferTypes] = useState([]);
  const [transferTypesLoading, setTransferTypesLoading] = useState(false);
  const [responseData, setResponseData] = useState();
  const [showResponse, setShowResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    isError: false,
  });

  // TOTP Specific
  const [isLoading, setIsLoading] = useState(true);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
      paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const handleSubmitUnloadFundsRequest = (formik) => {
    const { values, action } = formik;
    const payload = {
      ...values,
      // action: values.action?.value,
      action: "UNLOAD",
      amount: +values?.amount,
      transfer_type: values?.transfer_type?.value,
    };

    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      apiEndpointList.LOAD_UNLOAD_FUNDS.baseUrl +
      apiEndpointList.LOAD_UNLOAD_FUNDS.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setErrorResponse((prev) => ({
          isError: false,
        }));
        setResponseData(response.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Success"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponse(true);
        formik.resetForm();
      })
      .catch((error) => {
        setErrorResponse((prev) => ({
          ...prev,
          isError: true,
          ...error.response.data,
        }));
        setResponseData("");
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponse(true);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  const getTransferTypes = () => {
    try {
      setTransferTypesLoading(true);
      APIConfig.API_Client.post(
        apiEndpointList.GET_TRANSFER_TYPES.baseUrl +
        apiEndpointList.GET_TRANSFER_TYPES.endpoint,
        { cancelToken: source.token }
      ).then((response) => {
        const formattedResponse = response.data.map(({ name }) => ({
          value: name,
          label: name,
        }));
        setTransferTypes(formattedResponse);
      });
    } catch (error) {
      console.error("error from getTransferTypes", error);
    } finally {
      setTransferTypesLoading(false);
    }
  };

  useEffect(() => getTransferTypes(), []);

  const closeResponse = () => {
    setShowResponse(false);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              {/* <TextFieldSelect
                                id="action"
                                name="action"
                                onChange={(selectedOption) =>
                                    formik.setFieldValue("action", selectedOption)
                                }
                                onBlur={() => formik.setFieldTouched("action", true)}
                                value={formik.values.action}
                                options={actionDropDownValues}
                                noOptionsMessage={() => "No such action exists"}
                                label="Action"
                                required={true}
                                placeholder="Select action"
                                isformatOptionLabel={false}
                                isClearable={false}
                            /> */}

              <TextFieldInput
                id="action"
                name="action"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={"UNLOAD"}
                touched={formik.touched.action}
                error={formik.errors.action}
                placeholder="Action"
                label="Action"
                required={true}
                disabled={true}
              />

              <TextFieldSelect
                id="transfer_type"
                name="transfer_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("transfer_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("transfer_type", true)}
                value={formik.values.transfer_type}
                options={transferTypes}
                noOptionsMessage={() => "No such Transfer Type exists"}
                label="Transfer Type"
                required={true}
                placeholder="Select the transfer type"
                isformatOptionLabel={false}
                isClearable={false}
                isLoading={transferTypesLoading}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="account_number"
                name="account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_number}
                touched={formik.touched.account_number}
                error={formik.errors.account_number}
                placeholder="Enter account number"
                label="Account Number"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="counter_party_account_number"
                name="counter_party_account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.counter_party_account_number}
                touched={formik.touched.counter_party_account_number}
                error={formik.errors.counter_party_account_number}
                placeholder="Enter counter party account number"
                label="Counter Party Account Number"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="amount"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                touched={formik.touched.amount}
                error={formik.errors.amount}
                placeholder="Enter amount"
                label="Amount"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="counter_party_ifsc_code"
                name="counter_party_ifsc_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.counter_party_ifsc_code}
                touched={formik.touched.counter_party_ifsc_code}
                error={formik.errors.counter_party_ifsc_code}
                placeholder="Enter counter party IFSC code"
                label="Counter Party IFSC Code"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="purpose_message"
                name="purpose_message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose_message}
                touched={formik.touched.purpose_message}
                error={formik.errors.purpose_message}
                placeholder="Enter the purpose message"
                label="Purpose Message"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="provider_code"
                name="provider_code"
                value={provider.bank_name}
                placeholder="Enter provider code"
                label="Provider Code"
                required={false}
                disabled={true}
              />
              <TextFieldInput
                id="bank_reference_number"
                name="bank_reference_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bank_reference_number}
                touched={formik.touched.bank_reference_number}
                error={formik.errors.bank_reference_number}
                placeholder="Enter bank reference number"
                label="Bank Reference No."
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : "disabled"
                  }`}
                type="submit"
                disabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={(formik) => handleSubmitUnloadFundsRequest(formik)}
          formik={formik}
        />
      ) : null}
      {showResponse && (
        <ResponseDetails
          data={responseData}
          onClick={closeResponse}
          errorResponse={errorResponse}
        />
      )}
    </>
  );
};

export default ManageFunds;
