import React, { useContext, useEffect } from "react";

//component-import
import FormHeading from "../../../UI/FormHeading/FormHeading";
import Divider from "../../../UI/Divider/Divider";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import CategoryOneType from "./CategoryOneType/CategoryOneType";
import CategoryTwoType from "./CategoryTwoType/CategoryTwoType";
import CategoryThreeType from "./CategoryThreeType/CategoryThreeType";
// import FormTitle from "../../../UI/FormTitle/FormTitle";

//context-import
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

// Default value for Ownership type
const initialValues = {
  ownership_type: {
    value: "SOLE_PROPRIETORSHIP",
    label: "Sole Proprietorship",
  },
};

const validationSchema = Yup.object({
  ownership_type: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
});

const CompanyDetailsPA = () => {
  // ^ context
  const { setCompanyDetailOwnershipType } = useContext(OnBoardCompanyPAContext);

  // ^ formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    setCompanyDetailOwnershipType(formik?.values?.ownership_type?.value);
  }, [formik.values]);

  const ownershipType = [
    { value: "SOLE_PROPRIETORSHIP", label: "Sole Proprietorship" },
    { value: "PARTNERSHIP", label: "Partnership" },
    { value: "PRIVATE_LIMITED", label: "Private Limited" },
    { value: "PUBLIC_LIMITED", label: "Public Limited" },
    { value: "LLP", label: "Limited Liability Partnership (LLP)" },
    { value: "SOCIETY", label: "Society" },
    { value: "TRUST", label: "Trust" },
    { value: "GOVERNMENT", label: "Government" },
    { value: "HUF", label: "Hindu Undivided Family (HUF)" },
    { value: "NGO", label: "NGO (Section 8)" },
    { value: "INDIVIDUALS_PROFESSIONALS", label: "Individuals/Professionals" },
  ];

  const COMPANY_DETAIL_CARD = {
    SOLE_PROPRIETORSHIP: <CategoryOneType />,
    INDIVIDUALS_PROFESSIONALS: <CategoryOneType />,
    //
    HUF: <CategoryTwoType />,
    //
    PARTNERSHIP: <CategoryThreeType />,
    PRIVATE_LIMITED: <CategoryThreeType />,
    PUBLIC_LIMITED: <CategoryThreeType />,
    PRIVATE_LIMITED: <CategoryThreeType />,
    LLP: <CategoryThreeType />,
    SOCIETY: <CategoryThreeType />,
    TRUST: <CategoryThreeType />,
    GOVERNMENT: <CategoryThreeType />,
    NGO: <CategoryThreeType />,
  };

  return (
    <div className="ui-form-details">
      {/* Ownership Type */}
      <FormHeading headingText="Select Ownership Type" />
      <div className="ui-form-content">
        {/* <FormTitle titleText="Ownership Type" /> */}
        <div className="ui-form-inputs-section">
          {/* Select DropDown */}
          <TextFieldSelect
            id="ownership_type"
            name="ownership_type"
            onChange={(selectedOption) =>
              formik.setFieldValue("ownership_type", selectedOption)
            }
            onBlur={() => formik.setFieldTouched("ownership_type", true)}
            value={formik.values.ownership_type}
            options={ownershipType}
            noOptionsMessage={() => "No such transfer type exists"}
            label="Ownership Type"
            required={true}
            isClearable={false}
            //   isLoading={isLoading}
            placeholder="Select Ownership Type"
          />
        </div>

        <Divider />

        {/* Category Based Form */}
        <div>{COMPANY_DETAIL_CARD[formik?.values?.ownership_type?.value]}</div>
      </div>
    </div>
  );
};

export default CompanyDetailsPA;
