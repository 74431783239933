import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";
// Components
import SnackbarMessage from '../SnackbarMessage/SnackbarMessage';
import Table from '../../UI/AdvanceDataTable/AdvanceDataTable'
// Utils
import { APIConfig } from '../../services/apiConfiguration';
import apiEndpointList from '../../config/modules/customer_management/endpoint';
// Axios
import axios from 'axios';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementHistory = () => {
    const [companyOptions, setCompanyOptions] = useState([""]);
    const [isLoading, setIsLoading] = useState(false);


    const Headers = [
        {
            "Header": "_",
            "hideHeader": false,
            "columns": [
                {
                    "Header": "Created On",
                    "accessor": "created_on",
                    "sort": false
                },
                {
                    "Header": "Company ID",
                    "accessor": "company_id",
                    "sort": true
                },
                {
                    "Header": "Company Name",
                    "accessor": "company_name",
                    "sort": false
                },
                {
                    "Header": "Settlement URN",
                    "accessor": "settlement_urn",
                    "sort": false
                },
                {
                    "Header": "Decentro Transaction ID",
                    "accessor": "decentro_transaction_id",
                    "sort": false
                },
                {
                    "Header": "Status",
                    "accessor": "status",
                    "sort": false,
                },
                {
                    "Header": "Amount",
                    "accessor": "amount",
                    "sort": true
                },
                {
                    "Header": "Net Settlement Amount",
                    "accessor": "net_settlement_amount",
                    "sort": true
                },

                {
                    "Header": "Commission Amount",
                    "accessor": "commission_amount",
                    "sort": true
                },
                {
                    "Header": "Refund Amount",
                    "accessor": "refund_amount",
                    "sort": true
                },
                {
                    "Header": "Chargeback Amount",
                    "accessor": "chargeback_amount",
                    "sort": true
                },
                {
                    "Header": "Float Top Up Amount",
                    "accessor": "float_top_up_amount",
                    "sort": true
                },
            ]
        }
    ]

    const getCompanyOptions = () => {
        setIsLoading(true);
        APIConfig.API_Client.get(
            apiEndpointList.COMPANY_DETAIL_DATA.baseUrl +
            apiEndpointList.COMPANY_DETAIL_DATA.endpoint
            ,
            { cancelToken: source.token }
        )
            .then((response) => {
                const options = response.data.data.map((company) => ({
                    value: company.company_id,
                    label: company.common_name,
                }));
                setCompanyOptions(options);
                setIsLoading(false);

            })
            .catch((error) => {
                console.error("error from SettlementHistory,", error)
                ReactDOM.render(
                    <SnackbarMessage
                        msgtype="error"
                        msg={"Failed to load Company Name Options"}
                    />,
                    document.getElementById("snackbar")
                );
            });
    };

    useEffect(() => {
        getCompanyOptions();
    }, []);

    return (
        <>
            <Table
                columns={Headers}
                companyOptions={companyOptions}
                isOptionsLoading={isLoading}
            />
        </>
    )
}

export default SettlementHistory
