import React from "react";

// handler-function
import useOpenController from "./useOpenController";

// styles-import
import "./Accordion.scss";

export const Accordion = ({
  heading,
  defaultOpen = true,
  children,
  closable = true,
}) => {
  const { isOpen, toggle } = useOpenController(defaultOpen, closable);

  // If the accordion is not closable, prevent toggling when clicking on the header
  const handleHeaderClick = () => {
    if (closable) {
      toggle();
    }
  };

  return (
    <div className="ui-accordion-container">
      <ExpendableColumn
        heading={heading}
        isOpen={isOpen}
        onClick={handleHeaderClick}
      />
      {isOpen && <FormSection text={""} children={children} />}
      {/* <div className="ui-underline"></div> */}
    </div>
  );
};

export const ExpendableColumn = ({ heading, isOpen, onClick }) => {
  return (
    <div className="ui-column-container" onClick={onClick}>
      <div className="ui-column-text">{heading}</div>
      <button className="ui-expendable-button">
        <img
          style={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
            transition: "all 0.25s",
          }}
          src="/images/expand.svg"
          alt="expand arrow"
        />
      </button>
    </div>
  );
};

export const FormSection = ({ text, children }) => {
  return (
    <div className="ui-text-container">
      <div className="ui-text">{text}</div>
      <div className="ui-underline"></div>

      {children}
    </div>
  );
};
