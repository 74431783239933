import { useCallback, useState } from "react";

export default function useOpenController(initialState, closable = true) {
  const [isOpen, setOpenState] = useState(initialState);

  const toggle = useCallback(() => {
    if (closable) {
      setOpenState((state) => !state);
    }
  }, [closable, setOpenState]);

  return { isOpen, toggle };
}