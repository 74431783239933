import React from "react";
//styles-import
import "./ResponseDetails.scss";
//components-import
import CopyChip from "../../../UI/CopyChip/CopyChip";
// utils-import
import getSelectedProperties from "../../../utilities/getSelectedProperties";

const ResponseDetails = ({ data, onClick, errorResponse }) => {

  const imgSrc =
    data?.api_status === "SUCCESS"
      ? "/images/onsuccess-tick.svg"
      : data?.api_status === "FAILURE"
        ? "/images/onfailure-error.svg"
        : "/images/onfailure-error.svg";
  const apiStatus = errorResponse?.isError
    ? errorResponse?.api_status?.toLowerCase()
    : data?.api_status?.toLowerCase();

  const desiredProperties = apiStatus?.toUpperCase() === "SUCCESS" || apiStatus === "success" ? ['company_id', 'master_consumer_urn', 'decentro_txn_id', 'message'] : ['decentro_txn_id', 'message', 'response_key'];
  const response = apiStatus?.toUpperCase() === "SUCCESS" || apiStatus === "success" ? data : errorResponse

  const selected = getSelectedProperties(response, desiredProperties) || "-"
  const copyableData = selected

  return (
    <>
      {errorResponse?.isError ? (
        <div className="response-details-container">
          <h4 className="response-details-heading">
            <img
              src={"/images/onfailure-error.svg"}
              className={`${apiStatus}-status-icon`}
              alt="status-icon"
            />
            {apiStatus === "failure" ? "Failure" : "Success"} Response Details
            <div className="right-icons-container">
              <CopyChip
                label="Copy All"
                color="primary"
                variant="outlined"
                textToCopy={copyableData !== null ? copyableData : "-"}
                iconSrc="/images/copy-icon.svg"
              />
              <img
                src="/images/close.svg"
                className="close-response-details"
                alt="close"
                onClick={onClick}
              />
            </div>
          </h4>
          <div className="response-details-divider"></div>
          <div className="response-details">
            <ul className="response-list">
              {errorResponse.decentro_txn_id && (
                <li className="response-list-item">
                  <span className="field">Decentro Transaction ID: </span>
                  <span className="value">
                    {errorResponse.decentro_txn_id || "-"}
                  </span>
                </li>
              )}
              {errorResponse.message && (
                <li className="response-list-item">
                  <span className="field">Message: </span>
                  <span className="value">{errorResponse.message || "-"}</span>
                </li>
              )}
              {errorResponse.response_key && (
                <li className="response-list-item">
                  <span className="field">Response Key: </span>
                  <span className="value">
                    {errorResponse.response_key || "-"}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <div className="response-details-container">
          <h4 className="response-details-heading">
            <img
              src={imgSrc}
              className={`${apiStatus}-status-icon`}
              alt="status-icon"
            />
            {data?.api_status === "failure" ? "Failure" : "Success"} Response
            Details
            <div className="right-icons-container">
              <CopyChip
                label="Copy All"
                color="primary"
                variant="outlined"
                textToCopy={copyableData !== null ? copyableData : "-"}
                iconSrc="/images/copy-icon.svg"
              />
              <img
                src="/images/close.svg"
                className="close-response-details"
                alt="close"
                onClick={onClick}
              />
            </div>
          </h4>
          <div className="response-details-divider"></div>
          <div className="response-details">
            {data?.api_status === "SUCCESS" ? (
              <ul className="response-list">
                {data?.data?.company_id && (
                  <li className="response-list-item">
                    <span className="field">Company ID: </span>
                    <span className="value">
                      {data?.data?.company_id || "-"}
                    </span>
                  </li>
                )}
                {data?.data?.master_consumer_urn && (
                  <li className="response-list-item">
                    <span className="field">Master Consumer URN: </span>
                    <span className="value">
                      {data?.data?.master_consumer_urn || "-"}
                    </span>
                  </li>
                )}
                {data?.decentro_txn_id && (
                  <li className="response-list-item">
                    <span className="field">Decentro Transaction ID:</span>
                    <span className="value">{data.decentro_txn_id || "-"}</span>
                  </li>
                )}
                {data?.message && (
                  <li className="response-list-item">
                    <span className="field">Message: </span>
                    <span className="value">{data.message || "-"}</span>
                  </li>
                )}
              </ul>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
export default ResponseDetails;
