import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

// formik-imports
import { useFormik } from "formik";
import * as Yup from "yup";

// api-imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// component-imports
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import ToolTip from "../../../UI/ToolTip/ToolTip";
// import ResponseDetails from "../../../UI/ResponseDetails/ResponseDetails";

// context-import
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";

// styles imports
import "../../../UI/TextField/TextField.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const validationSchema = Yup.object({
  company_id: Yup.number().required("Company ID is required"),
  provider_code: Yup.string().required("Provider Code is required"),
  param_name: Yup.string().required("Param Name is required"),
  param_value: Yup.boolean().required("Boolean Status is required"),
  is_value_to_be_encrypted: Yup.boolean().required(
    "Boolean Status is required"
  ),
});

const MultiAttemptPA = () => {
  // ^ context
  const { companyID, toSettlementConfig } = useContext(OnBoardCompanyPAContext);

  const initialValues = {
    company_id: companyID,
    provider_code: "Yes Bank - yesb",
    param_name: "ARE_MULTIPLE_ATTEMPTS_ENABLED_FOR_PAYMENT_LINKS",
    param_value: true,
    is_value_to_be_encrypted: false,
  };

  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      let payload = {
        company_id: companyID,
        provider_code: "yesb",
        param_name: values.param_name,
        param_value: values.param_value,
        is_value_to_be_encrypted: values.is_value_to_be_encrypted,
      };
      // & MultiAttempt API Call
      formik.setSubmitting(true);
      APIConfig.API_Client.post(
        paAPIEndpoints.MULTI_ATTEMPT.baseUrl +
          paAPIEndpoints.MULTI_ATTEMPT.endpoint,
        payload,
        { cancelToken: source.token }
      )
        .then((response) => {
          // setShowResponseDetails(true);
          formik.resetForm();
          formik.setFieldValue("param_value", true);
          formik.setFieldValue("is_value_to_be_encrypted", false);

          // To Settlement Account Config Tab
          toSettlementConfig();

          ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={response.data.message} />,
            document.getElementById("snackbar")
          );
        })
        .catch((error) => {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="company_id"
                name="company_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_id}
                touched={formik.touched.company_id}
                error={formik.errors.company_id}
                placeholder="Enter company ID"
                label="Company ID"
                required={true}
                disabled={true}
              />

              <TextFieldInput
                id="provider_code"
                name="provider_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.provider_code}
                touched={formik.touched.provider_code}
                error={formik.errors.provider_code}
                placeholder="Enter provider code"
                label="Provider Code"
                required={true}
                disabled={true}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="param_name"
                name="param_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.param_name}
                touched={formik.touched.param_name}
                error={formik.errors.param_name}
                placeholder="Enter Param Name"
                label="Param Name"
                required={true}
                disabled={true}
              />

              <div className="ui-form-toggle-field">
                <div class="ui-label">
                  Param Value
                  <ToolTip text={false} requiredField={true} isToolTip={false}>
                    {/* {isToolTip && (
                      <div className="ui-info-icon">
                        <img src="/images/info-icon.svg" alt="" />
                      </div>
                    )} */}
                  </ToolTip>
                </div>

                <div class="ui-switch-wrapper">
                  <label className="ui-switch">
                    <input
                      type="checkbox"
                      id="param_value"
                      name="param_value"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.param_value}
                    />
                    <div className="ui-slider"></div>
                  </label>
                </div>
              </div>
            </div>

            <div class="ui-form-inputs-section">
              <div className="ui-form-toggle-field">
                <div class="ui-label">
                  Encrypt Value
                  <ToolTip text={false} requiredField={true} isToolTip={false}>
                    {/* {isToolTip && (
                      <div className="ui-info-icon">
                        <img src="/images/info-icon.svg" alt="" />
                      </div>
                    )} */}
                  </ToolTip>
                </div>

                <div class="ui-switch-wrapper">
                  <label className="ui-switch">
                    <input
                      type="checkbox"
                      id="is_value_to_be_encrypted"
                      name="is_value_to_be_encrypted"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.is_value_to_be_encrypted}
                    />
                    <div className="ui-slider"></div>
                  </label>
                </div>
              </div>
            </div>

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${
                  formik.isValid && !formik.isSubmitting ? "active" : "disabled"
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* {showResponseDetails && (
        <ResponseDetails data={responseDetails} onClick={handleClick} />
      )} */}
    </>
  );
};

export default MultiAttemptPA;
