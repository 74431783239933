import React from "react";

// ui-component import
import ToolTip from "../ToolTip/ToolTip";

// styes-import
import "./ToggleField.scss";

const ToggleField = ({
  id,
  isToolTip = false,
  required = false,
  disabled = false,
  checked,
  onChange,
  label,
}) => {
  return (
    <div className="ui-form-toggle-field">
      <label className="ui-label" htmlFor={id}>
        {label}{" "}
        <ToolTip
          text={isToolTip}
          requiredField={required}
          isToolTip={isToolTip}
        >
          {isToolTip && (
            <div className="ui-info-icon">
              <img src="/images/info-icon.svg" alt="" />
            </div>
          )}
        </ToolTip>
      </label>
      <div className="ui-switch-wrapper">
        <label className="ui-switch">
          <input
            id="is_active"
            name="is_active"
            type="checkbox"
            checked={checked} // Use checked attribute instead of value
            onChange={onChange}
            disabled={disabled}
          />
          <div className="ui-slider"></div>
        </label>
      </div>
    </div>
  );
};

export default ToggleField;
