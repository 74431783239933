import React from "react";

//components-import
import CopyChip from "../../../UI/CopyChip/CopyChip";

//styles-import
import "./ResponseDetails.scss";
// utils-import
import getSelectedProperties from "../../../utilities/getSelectedProperties";

const ResponseDetails = ({ data, onClick }) => {
  const status = data.api_status.toLowerCase();
  const imgSrc =
    data.api_status === "FAILURE"
      ? "/images/onfailure-error.svg"
      : "/images/onsuccess-tick.svg";

  const desiredProperties = ['decentro_txn_id', 'message'];

  const selected = getSelectedProperties(data, desiredProperties) || "-"
  const copyableData = selected

  return (
    <>
      <div className="response-details-container">
        <h4 className="response-details-heading">
          <img
            src={imgSrc}
            className={`${status}-status-icon`}
            alt="status-icon"
          />
          {status === "failure" ? "Failure" : "Success"} Response Details
          <div className="right-icons-container">
            <CopyChip
              label="Copy All"
              color="primary"
              variant="outlined"
              textToCopy={copyableData !== null ? copyableData : "-"}
              iconSrc="/images/copy-icon.svg"
            />
            <img
              src="/images/close.svg"
              className="close-response-details"
              alt="close"
              onClick={onClick}
            />
          </div>
        </h4>
        <div className="response-details-divider"></div>
        <div className="response-details">
          {status === "success" ? (
            <ul className="response-list">
              <li className="response-list-item">
                <span className="field">Decentro Transaction ID: </span>
                <span className="value">{data?.decentro_txn_id || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">Message: </span>
                <span className="value">{data?.message || "-"}</span>
              </li>
            </ul>
          ) : null}

          {data.api_status === "FAILURE" ? (
            <ul className="response-list">
              <li className="response-list-item">
                <span className="field">Decentro Transaction ID: </span>
                <span className="value">{data?.decentro_txn_id || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">Message: </span>
                <span className="value">{data?.message || "-"}</span>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ResponseDetails;
