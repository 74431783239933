import React, { useContext, useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

// API imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// component imports
import IpDetailsDialog from "../IPManagement/IpDetailsDialog/IpDetailsDialog";
import TableLoader from "../Shared/TableLoader/TableLoader";
import Error from "../Shared/Error/Error";
import DataTable from "../SortTableSummary/DataTable";
import VisibilityIcon from "@mui/icons-material/Visibility";

// context imports
import IPManagementActionContext from "../../contexts/IPManagementActionContext";

// utils import
import capitalizeFirstLetter from "../../utilities/capitalizeLetter";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const IPManagementDataTable = () => {
  const { setTableEditData, editIps } = useContext(IPManagementActionContext);

  const [combinedData, setCombinedData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    status: "",
    message: "",
  });

  const handleEdit = (row) => {
    editIps();
    setTableEditData({
      id: row.companyId,
      edit: true,
      companyName: row.commonName,
      allowCount: row.totalIPs,
      pluginCreated: row.pluginCreated,
    });
  };

  const handleIconClick = (row) => {
    ReactDOM.render(
      <IpDetailsDialog data={row} />,
      document.getElementById("add-customer-components")
    );
  };

  const ipManagementTableHeaders = useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        columns: [
          {
            Header: "Company Id",
            accessor: "companyId",
            className: "header-small",
            sort: true,
          },
          {
            Header: "Company Name",
            accessor: "commonName",
            className: "header-medium",
            sort: true,
            disableFilters: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.commonName
                  ? capitalizeFirstLetter(row.row.original.commonName)
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Total IPs",
            accessor: "totalIPs",
            className: "header-large",
            sort: true,
            disableFilters: true,
          },
          {
            Header: "IP Address(es)",
            accessor: "ipAddresses",
            className: "header-large",
            sort: false,
            disableFilters: true,
            Cell: ({ row }) => (
              <div
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() =>
                  row.original.totalIPs > 0
                    ? handleIconClick(row.original)
                    : null
                }
              >
                {row.original.totalIPs > 0 ? (
                  <VisibilityIcon
                    style={{
                      color: "#0092ff",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                ) : (
                  "-"
                )}
              </div>
            ),
          },
          {
            Header: "Action",
            accessor: "action",
            sort: false,
            className: "last-child-header header-small",
            Cell: (row) => (
              <div>
                <button
                  onClick={() => handleEdit(row.row.original)}
                  className="user-table-edit-btn"
                >
                  Edit
                  <img src="/images/back.svg" alt="right-arrow" />
                </button>
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  const getCompanyData = async (company_id) => {
    let payload = {};
    if (company_id) payload.company_id = company_id;

    try {
      const response = await APIConfig.API_Client.post(
        paAPIEndpoints.FETCH_COMPANY_DETAILS.baseUrl +
          paAPIEndpoints.FETCH_COMPANY_DETAILS.endpoint,
        payload,
        { cancelToken: source.token }
      );
      return response.data;
    } catch (error) {
      setIsError(true);
      setErrorResponse({
        status: error?.response?.status,
        message: error?.message,
      });
      return [];
    }
  };

  const getIPData = async (client_id) => {
    let payload = {};
    if (client_id) payload.client_id = client_id;
    try {
      const response = await APIConfig.API_Client.post(
        paAPIEndpoints.FETCH_IP_DETAILS.baseUrl +
          paAPIEndpoints.FETCH_IP_DETAILS.endpoint,
        payload,
        { cancelToken: source.token }
      );
      return response.data;
    } catch (error) {
      setIsError(true);
      setErrorResponse({
        status: error?.response?.status,
        message: error?.message,
      });
      return [];
    }
  };

  //   send below when u need to send client_id
  //   JSON.parse(localStorage.getItem("user"))?.client_id;

  const fetchData = async (company_id, client_id) => {
    const companyData = company_id
      ? await getCompanyData(company_id)
      : await getCompanyData();
    const ipData = client_id ? await getIPData(client_id) : await getIPData();

    // Combine data
    const combined = companyData.map((company) => {
      const ipInfo = ipData.find((ip) => ip.client_id === company.client_id);
      return {
        companyId: company.company_id,
        commonName: company.common_name,
        totalIPs: ipInfo ? ipInfo.allow_count : "-",
        ipAddresses: ipInfo ? ipInfo.allow.join(", ") : "-",
        pluginCreated: !!ipInfo,
      };
    });

    setCombinedData(combined);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchCompany = (event) => {
    event.preventDefault();
    fetchData(10, JSON.parse(localStorage.getItem("user"))?.client_id);
  };

  return (
    <div className="all-users-table-container">
      {!isError ? (
        combinedData.length ? (
          <DataTable
            columns={ipManagementTableHeaders}
            data={combinedData}
            csvName="IPManagementSummaryDetails"
          />
        ) : (
          <TableLoader />
        )
      ) : (
        <Error type={errorResponse} />
      )}
    </div>
  );
};

export default IPManagementDataTable;
