import { REGEXP } from "./validators/inputValidators";

/**
 * Extracts and formats the date from a timestamp.
 * @param {string} timestamp - The input timestamp in the format "dd/mm/yyyy hh:mm AM/PM".
 * @returns {string} - The formatted date as "yyyy-mm-dd".
 */
function formattedDate(timestamp) {
  // Regular expression to match the date part of the timestamp
  const match = timestamp.match(REGEXP.dataTableDateTimeRegex);

  if (match) {
    const [day, month, year] = match;
    // Return the date in "yyyy-mm-dd" format
    return `${year}-${month}-${day}`;
  }

  // Return null or an error message if the format is incorrect
  return null;
}

export default formattedDate;
