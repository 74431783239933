import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Axios
import axios from "axios";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Components
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import { TotpModal } from "./TotpModal/TotpModal";
// Utils
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { randomUUID } from "../../services/randomUUID";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const ConfigureMLA = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseData, setResponseData] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    isError: false,
  });

  // TOTP Specific
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const initialValues = {
    company_name: "",
    provider: "YES Bank",
  };

  const validationSchema = Yup.object({
    company_name: Yup.object().shape({
      value: Yup.string().required("Please select an option"),
      label: Yup.string().required("Please select an option"),
    }),
  });

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
      paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const configureMLAAction = (formik) => {
    const { values, action } = formik;
    const payload = {
      company_id: values?.company_name?.value,
      provider_code: "yesb",
      // ifsc: values.ifsc,
      // account_number: values.account_number,
    };
    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.COMPANY_MASTER_SETUP.baseUrl +
      paAPIEndpoints.COMPANY_MASTER_SETUP.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setErrorResponse({
          isError: false,
        });
        setResponseData(response.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Success"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponse(true);
        formik.resetForm();
      })
      .catch((error) => {
        setErrorResponse((prev) => ({
          ...prev,
          isError: true,
          ...error.response.data,
        }));
        setResponseData("");
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponse(true);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      //! reset previous values
      setResponseData("");
      setErrorResponse({
        isError: false,
      });
      setShowResponse(false);
      submitHandler(values, action);
    },
  });
  // Fetching Companies to show in dropdown
  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      paAPIEndpoints.GET_ALL_COMPANY_DETAIL.baseUrl +
      paAPIEndpoints.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.company_id,
          label: item.common_name,
        }));
        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getCompanyOptions();
  }, []);
  const closeResponse = () => {
    setShowResponse(false);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_name"
                name="company_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_name", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                required={true}
                isLoading={isLoading}
                placeholder="Select company name"
                isformatOptionLabel={true}
              />
              <TextFieldInput
                id="provider"
                name="provider"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.provider}
                touched={formik.touched.provider}
                error={formik.errors.provider}
                placeholder="Select provider"
                label="Provider"
                required={true}
                disabled={true}
              />
            </div>
            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : "disabled"
                  }`}
                type="submit"
                disabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={(formik) => configureMLAAction(formik)}
          formik={formik}
        />
      ) : null}
      {showResponse && (
        <ResponseDetails
          data={responseData}
          onClick={closeResponse}
          errorResponse={errorResponse}
        />
      )}
    </>
  );
};
export default ConfigureMLA;
