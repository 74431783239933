import React from "react";
//styles-import
import "./ResponseDetails.scss";
//components-import
import CopyChip from "../../../../UI/CopyChip/CopyChip";
// utils-import
import getSelectedProperties from "../../../../utilities/getSelectedProperties";

const ResponseDetails = ({ data, onClick, errorResponse }) => {
    const apiStatus = errorResponse?.isError ? errorResponse?.api_status?.toLowerCase() : data?.api_status?.toLowerCase();

    const imgSrc =
        apiStatus === "SUCCESS" || apiStatus === "success"
            ? "/images/onsuccess-tick.svg"
            : "/images/onfailure-error.svg"

    const desiredProperties = apiStatus === "SUCCESS" || apiStatus === "success" ? ['decentro_txn_id', 'updated_balance', 'api_status', 'transfer_status'] : ['decentro_txn_id', 'message', 'response_key'];
    const response = errorResponse?.isError ? errorResponse : data

    const selected = getSelectedProperties(response, desiredProperties) || "-"
    const copyableData = selected

    return (
        <>
            {errorResponse?.isError ?
                <div className="response-details-container">
                    <h4 className="response-details-heading">
                        <img
                            src={"/images/onfailure-error.svg"}
                            className={`${apiStatus || "failure"}-status-icon`}
                            alt="status-icon"
                        />
                        Failure Response Details
                        <div className="right-icons-container">
                            <CopyChip
                                label="Copy All"
                                color="primary"
                                variant="outlined"
                                textToCopy={copyableData !== null ? copyableData : "-"}
                                iconSrc="/images/copy-icon.svg"
                            />
                            <img
                                src="/images/close.svg"
                                className="close-response-details"
                                alt="close"
                                onClick={onClick}
                            />
                        </div>
                    </h4>
                    <div className="response-details-divider"></div>

                    <div className="response-details">
                        <ul className="response-list">
                            {errorResponse?.decentro_txn_id && <li className="response-list-item">
                                <span className="field">Decentro Transaction ID: </span>
                                <span className="value">
                                    {errorResponse.decentro_txn_id || "-"}
                                </span>
                            </li>}
                            {errorResponse?.message &&
                                <li className="response-list-item">
                                    <span className="field">Message: </span>
                                    <span className="value">
                                        {errorResponse.message || "-"}
                                    </span>
                                </li>}
                            {errorResponse?.response_key &&
                                <li className="response-list-item">
                                    <span className="field">Response Key: </span>
                                    <span className="value">
                                        {errorResponse.response_key || "-"}
                                    </span>
                                </li>}
                        </ul>

                    </div>
                </div>
                :
                <div className="response-details-container">
                    <h4 className="response-details-heading">
                        <img
                            src={imgSrc}
                            className={`${apiStatus || "success"}-status-icon`}
                            alt="status-icon"
                        />
                        {data?.api_status === "failure" ? "Failure" : "Success"} Response Details
                        <div className="right-icons-container">
                            <CopyChip
                                label="Copy All"
                                color="primary"
                                variant="outlined"
                                textToCopy={copyableData !== null ? copyableData : "-"}
                                iconSrc="/images/copy-icon.svg"
                            />
                            <img
                                src="/images/close.svg"
                                className="close-response-details"
                                alt="close"
                                onClick={onClick}
                            />
                        </div>

                    </h4>
                    <div className="response-details-divider"></div>
                    <div className="response-details">
                        {data?.api_status === "SUCCESS" ? (
                            <ul className="response-list">
                                {data?.decentro_txn_id &&
                                    <li className="response-list-item">
                                        <span className="field">Decentro Transaction ID:</span>
                                        <span className="value">
                                            {data.decentro_txn_id || "-"}
                                        </span>
                                    </li>}
                                {data?.data?.updated_balance &&
                                    <li className="response-list-item">
                                        <span className="field">Updated Balance: </span>
                                        <span className="value">{data.data.updated_balance || "-"}</span>
                                    </li>}
                                {data?.api_status && <li className="response-list-item">
                                    <span className="field">API Status: </span>
                                    <span className="value">{data.api_status || "-"}</span>
                                </li>}
                                {data?.data?.transfer_status && <li className="response-list-item">
                                    <span className="field">Transfer Status: </span>
                                    <span className="value">
                                        {data.data.transfer_status || "-"}
                                    </span>
                                </li>}
                            </ul>
                        ) : null}
                    </div>
                </div>
            }
        </>
    );
};

export default ResponseDetails;
